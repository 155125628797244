export default {
  payChannel: 'Payment Channels',
  cashierDesk: 'Checkout Counter',
  billAddress: 'Bill Address',
  paySuccess: 'Payment Successful',
  payError: 'Payment Failure',
  harvestAddress: 'Shipping Address',
  orderAddress: 'Billing address',
  selectAddress: 'Select a shipping address',
  addOrderAddress: 'Please add the billing address first',
  addSelectAddress: 'Please select a shipping address',
  noAddress: 'No shipping address available',
  holder: {
    inputPayPwd: 'Please set the payment password'
  },
  transferTitle: 'Bank Transfer',
  changeAddress: 'Change Address',
  editText: 'Edit',
  payWithbalance: 'Pay With acbuy Balance',
  bankTransferRemittance: 'Bank Transfer',
  transferTips:
    '0 Service Fee On acbuy For Remittance. The Higher The Order Amount, The More Savings!',
  pay: 'Top-Up Offers',
  plsSetPsd: 'Please set payment password',
  thirdPayTips: 'Payments are available worldwide and in multiple currencies',
  payProcessing: 'Payment processing'
};
