import { USER_STORE_KEY } from '@/stores/config'

// 获取localStorage 中的userStore
export const getLocalUserStore = () => {
  return JSON.parse(localStorage.getItem(USER_STORE_KEY) || `{ "language": "en", "currency": "USD" }`)
}

// 获取默认语言
export const getLang = () => {
  const userConfig = getLocalUserStore()
  if (!userConfig.language) {
    // 常规浏览器语言和IE浏览器
    userConfig.language = (navigator.language || navigator.browserLanguage).toLowerCase();
    if (userConfig.language.indexOf("zh") === 0) {
      userConfig.language = "cn";
      userConfig.currency = 'CNY'
    } else if (userConfig.language.indexOf("es") === 0) {
      userConfig.language = "es";
      userConfig.currency = 'USD'
    }  else {
      userConfig.language = "en";
      userConfig.currency = 'USD'
    }
    // else if (userConfig.language.indexOf("ko") === 0) {
    //   userConfig.language = "ko";
    //   userConfig.currency = 'USD'
    // }
  }
  return userConfig
}