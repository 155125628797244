export default {
  myBalance: 'My balance',
  topUp: 'Top up',
  pay: 'Top up',
  repayment: 'Repayment',
  balance: 'balance',
  usableBalance: 'credit available',
  inputBalance: 'Please Enter Amount',
  payBalance: 'recharge amount',
  pecenter: 'Personal Center',
  lang: 'Language',
  cart: 'Shopping Cart',
  recipientName: 'Consignee',
  goRecharge: 'Top Up Now',
  phone: 'Phone',
  region: 'Region',
  address: 'Detailed Address',
  country: 'Country or Region',
  province: 'Province',
  city: 'City',
  postcode: 'Post/ZIP code',
  action: 'Operation',
  mobileSettings: 'Mobile settings',
  name: 'Name',
  debt: 'DEBT',
  add: 'Add Credit Card Billing Address',
  addSuccess: 'Add success',
  editSuccess: 'Edit success',
  editBtn: 'Edit',
  edit: 'Edit Credit Card Billing Address',
  del: 'Delete',
  setDefault: 'Set as default',
  defaultAddress: 'Default Address',
  setDefaultAddress: 'Set to the default shipping address',
  setSuccess: 'Set success',
  myAddress: 'My address',
  addAddress: 'Add New Address',
  tradeTime: 'Transaction Time',
  type: 'Type',
  incomeOrExpend: 'Income/Expenditure',
  accountBalance: 'Account Balance',
  tradeNo: 'Transaction ID',
  remark: 'Remark',
  tag: 'Address Tag',
  addBillAddress: 'Add Credit Card Billing Address',
  deleteBillAddress: 'Delete Billing Address',
  editBillAddress: 'Edit Billing Address',
  mergePay: 'Pay together',
  username: 'User Name',
  gender: 'Gender',
  birthday: 'Birthday',
  avatar: 'Avatar',
  email: 'Email',
  site: 'Site',
  myOrder: 'Order',
  sideBar: {
    myAccount: 'My account',
    addressManage: 'Address management',
    myAsset: 'Wallet',
    billAddress: 'Billing address'
  },
  holder: {
    receiver: 'Recipient',
    firstName: 'First Name',
    name: 'Last Name',
    region: 'Please select Province/city/District/street',
    address:
      'Please enter detailed address information, such as road, house number, community, building number, unit and so on',
    phone: 'Phone number, mobile phone number must fill in a field',
    input: 'please input',
    select: 'please select',
    tagHolder: 'Max . 120letters(Optional)',
    postCodeNoMatch: 'The zip code does not match the region'
  },
  forget: {
    accountSafe: 'Account Security',
    setPwd: 'Change Password',
    inputBindEmail: 'Please enter your bound email',
    inputVerifyCode: 'Please enter verification code',
    inputPwd: 'Please enter new password'
  },
  accountInfo: {
    name: 'Account',
    selfInfo: 'Personal Information',
    cardBillAddress: 'Credit Card Billing Address',
    shipAddress: 'Shipping Address',
    editUser: 'Edit User Information',
    inputCountry: 'Please enter your country',
    editEmail: 'Edit Email',
    editEmailHint:
      '(Recommended only when email is set incorrectly or has problems)',
    editEmailHint1:
      'For your account security, you need to pass the security verification with "Email verification code + login password" first',
    editEmailHint2:
      'For your account security, you need to pass the security verification with "Email verification code" first',
    currentEmail: 'Current Email',
    clickVerify: 'Send',
    reGet: 'Resend',
    nextStep: 'Next Step',
    notReGetCode: ' Resend after',
    logoutAccount: 'Log out account',
    emailNotEmpty: 'Email cannot be empty',
    logoutReason: 'Please select the reason for logging out',
    latelyLogin: 'Recent Login',
    moreRecord: 'More Records',
    visitRecord: 'Visit Records',
    visitTime: 'Visit Time',
    ipAndDevice: 'IP Address & Device Type',
    editLoginPwdHint:
      'A high-security password can make your account more secure. It is recommended that you change your password regularly and set a password that includes numbers and letters and is more than 6 digits long.',
    editPayPwdHint:
      'The password required when using acbuy balance to pay, set the payment password to ensure payment security.',
    editVerifyEmailHint:
      'In order to protect your account’s safety, please verify your email.',
    payPwd: 'Payment Password',
    editPayPwd: 'Change Payment Password',
    setPayPwd: 'Set Payment Password',
    deleteAccount: 'Delete Account'
  },
  logoutReasonEnum: {
    0: 'Security/Privacy Issues',
    1: 'Poor Customer Service',
    2: 'Costs Too High',
    3: 'Complicated Shopping Process',
    4: 'Other Reasons'
  },
  tradeTypeEnum: {
    0: 'Income',
    1: 'Expenditure'
  },
  asset: {
    currentBalance: 'Current Balance',
    canWithdrawBalance: 'Amount Available for Withdrawal',
    withdraw: 'Withdraw',
    serialNo: 'Serial No.',
    amount: 'Amount',
    lossAmount: 'Amount Spent',
    withdrawAmount: 'Withdrawal Amount',
    inputWithdrawAmount: 'Please enter withdrawal amount',
    withdrawSuccess: 'Withdrawal request submitted successfully',
    transactionDate: 'Transaction Date',
    withdrawMethod: 'Withdrawal Method',
    availableAmount: 'Available Amount',
    transactionNo: 'Transaction Number',
    withdrawMsg:
      'Note: The balance withdrawal will be returned through the original channel. The balance will be returned to the payment account of the withdrawal transaction in accordance with the historical expenditure record.',
    transactionRecordsText: 'Available Amount Transaction Records',
    withdrawRecordText: 'Withdrawal Records'
  },
  package: {
    packageInfo: 'Package Information',
    weightHaveUnit: 'Weight (g)',
    sizeHaveUnit: 'Size (cm)',
    freight: 'Shipping Fee',
    fee: 'Fee',
    transportMode: 'Delivery Mode',
    allPackage: 'All Packages',
    myPackage: 'Parcel'
  },
  packageStatusEnum: {
    0: 'Outstanding funds',
    1: 'Unpaid',
    2: 'In Transit',
    3: 'Completed',
    4: 'Cancelled'
  },
  storage: {
    name: 'Warehouse',
    warehouseTime: 'Warehouse Entry Time',
    submitTransport: 'Submit for Delivery',
    lineCanUse: 'Pieces Available',
    commonImg: 'QC Photos',
    customPhoto: 'Customized photos',
    extendCare: 'Extended Storage',
    extendHint:
      'Your goods will be held in the warehouse for an extended period of (30*N) days and the expiration time is',
    packMode: 'Packaging Method',
    retainPack: 'Reserved Packing',
    normalPack: 'Default Packaging',
    normalPackHint: 'Items will be packed with their original packaging.',
    foldPack: 'Folded Packaging',
    foldPackHint:
      "For non-glue-fixed cartons, warehouse will disassemble them, fold them flat and put them into user's parcel, which can reduce the parcel volume to some extent. But there are also cases of increasing the package volume (when there are fewer goods, the folding length and width of the carton may increase).",
    discardPack: 'Discard Package',
    discardPackHint:
      'After package is discarded, seller may not accept any aftersales and package cannot be found/added back.',
    photoTemplate: 'Photo Template'
  },
  operate: 'Operation',
  editAddress: 'Edit Address',
  underPaid: 'Unpaid',
  goTopay: 'Go to Pay',
  viewRecord: 'View Record',
  faq: 'FAQ',
  question1: 'Q: Would my account be frozen if I submit for a withdrawal?',
  response1:
    'A: After applying for withdrawal, the account will freeze the amount of the application to ensure that the refund amount is consistent with the amount you applied for.',
  question2:
    'Q: Does "Completed" on withdrawal records mean that the withdrawal of the fund is successful?',
  response2:
    'A: "Completed" means that acbuy has arranged a refund however the time it takes to arrive your original account depends on the processing time of the 3rd party platform.',
  question3: 'Q: Is the withdrawal based on real time exchange rate?',
  response3:
    'A: No, the withdrawal exchange rate is based on the time the fund was topped up.',
  question4:
    'Q: How do you withdraw when the account balance is different from the withdrawal amount?',
  response4:
    'A: When the account balance is greater than the withdrawal amount, you can apply in batches until the account balance is 0. When the account balance is less than the withdrawal amount, you can apply for a one-time full withdrawal.',
  stillRecharge: 'Not Enough To Pay For The Order, Still Need To Recharge',
  amountDue: 'Payable Amount',
  handingFee: 'Handling Fee',
  coupon: 'Coupon',
  couponPlaceholder: 'Redeem coupons with coupon codes',
  off: 'OFF',
  minFee: 'Min. Fee',
  freightText: 'Freight',
  limitTo: 'Limit to',
  shippingUsage: 'Shipping Usage',
  availableWarehouse: 'Available Warehouse',
  shippingFeeText: 'Shipping Fee (Exclude Custom Clearance Fee)',
  internationalParcel: 'International Parcel',
  to: 'to',
  availableCoupons: 'Available Coupons',
  usedCoupons: 'Used Coupons',
  expiredCoupons: 'Expired Coupons',
  exchangeSuccess: 'Exchange Success!',
  myAffiliate: {
    name: 'My Affiliate'
  },
  affiliateShare: 'Share',
  copyText: 'Copy',
  affiliateShareMsg:
    'Shipping Payments Made Through Shared Links Will Generate Commission Earnings For You.',
  businessType: 'Business Type',
  payWay: 'Transaction Type',
  bzOrderNo: 'AC No.',
  amountDueTooltip:
    'Due to currency exchange rate conversion calculations, there may be a difference of approximately 0.01 or 0.02',
  balanceStatement: 'Balance statement',
  billFlow: 'Bill'
};
