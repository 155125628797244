export default {
  useMoreAddress: 'Usar otra dirección',
  hideAddress: 'Ocultar dirección',
  productInventory: 'Lista de productos',
  tag: 'Etiqueta',
  retainTag: 'Conservar etiqueta',
  delTag: 'Eliminar etiqueta',
  externalPack: 'Embalaje exterior',
  packDetail: 'Detalles del embalaje',
  postLine: 'Ruta de envío',
  selectLine: 'Seleccionar ruta',
  editLine: 'Modificar ruta',
  service: 'Servicio',
  cancelServe: 'Cancelar servicio',
  previewPackage: 'Vista previa del paquete',
  postCountry: 'País de envío',
  packageNo: 'Número de paquete',
  deliver: 'Enviar a',
  packageDetail: 'Detalles del paquete',
  addressInfo: 'Información de la dirección',
  voWeight: 'Peso volumétrico',
  firstWeight: 'Primer peso',
  continueWeight: 'Peso adicional',
  actWeight: 'Peso real',
  packageStatus: 'Estado del paquete',
  freightDetail: 'Información de costos',
  declareCurrency: 'Moneda de declaración',
  selfDeclare: 'Declaración independiente',
  packageDeclarePrice: 'Importe total declarado del paquete',
  youPackageProduct: 'Los productos en su paquete',
  editYourDeclare: 'Por favor, edite su información de declaración',
  startDeclare: 'Iniciar declaración',
  declareType: 'Tipo de declaración',
  productNo: 'Número de producto',
  declareProductPrice: 'Declarar precio unitario',
  declareTotalPrice: 'Monto total de productos declarados',
  editDeclare: 'Modificar declaración',
  declareLinePriceHint:
    'El monto total declarado más bajo para la línea actual: {min}, el más alto: {max}',
  classMaxNumHint:
    'El número de categorías individuales ha alcanzado el límite superior {max}',
  addDeclareProduct: 'Agregar productos declarados',
  plsConfirmInfo: 'Por favor, confirme la información',
  wipePackProduct: 'Productos sin embalaje',
  foldPackProduct: 'Productos plegables',
  wipeTagProduct: 'Productos sin etiqueta',
  aboutCalcWeightExplain:
    'Como el paquete no está embalado actualmente, no conocemos el peso real del paquete en esta etapa, por lo que solo podemos confiar en varios parámetros existentes para estimar el peso del paquete, a fin de cobrar el depósito. Después de que el paquete esté embalado y se obtenga el peso real, se devolverá o cobrará la diferencia.',
  expressInfo: 'Información de seguimiento',
  deliveryMode: 'Modo de distribución',
  targetCountry: 'País de destino',
  cancelPackage: 'Cancelar paquete',
  cancelPackageHint:
    '¿Confirma que desea cancelar este paquete? No podrá recuperarlo después de cancelar.',
  isDelPackage: '¿Confirma que desea eliminar permanentemente el paquete?',
  delPackageHint:
    'Después de la eliminación permanente, ya no podrá ver el paquete, ¡por favor, tenga precaución!',
  receivedTips: 'Su paquete ha sido recibido, bienvenido a usarlo de nuevo',
  packageQuestion: 'Hay un problema con la entrega del paquete, usted puede',
  afterSale: 'Solicitar servicio posventa',
  repair: 'Pago adicional',
  ucan: 'Usted puede',
  clickAdd: 'Hacer clic para agregar',
  addCategoryTips: 'No hay nombre de producto que desee declarar',
  noCarrierLines: 'No hay rutas de envío disponibles',
  confirmPackageTitle: '¿Ha confirmado la recepción de los productos?',
  confirmPackageTips:
    'Para proteger sus derechos posventa, confirme la recepción después de verificar que el paquete es correcto',
  receiptSuccess: 'Recepción exitosa',
  position: 'Posición',
  time: 'Tiempo',
  duty: 'Número de impuesto',
  serveStatusEnum: {
    1: 'Fotos personalizadas',
    2: 'Extender el período de custodia'
  },
  inputCareTime: 'Por favor, seleccione el tiempo de almacenamiento',
  holder: {
    noInputSearch: 'Nº de paquete/ID de producto',
    inputEditDeclareInfo: 'Por favor, edite su información de declaración',
    packageRemark:
      'Si tiene algún otro requisito especial para el paquete, por favor anótelo aquí'
  },
  calcFee: 'Tarifa estimada',
  realFee: 'Tarifa real',
  declare: {
    repeatDeclare: 'Tipo de declaración duplicada',
    addDeclareType: 'Por favor, agregue el tipo de declaración'
  },
  feeEnum: {
    totalAmount: 'Depósito estimado',
    deposit: 'Costo estimado',
    overLongFee: 'Tarifa por exceso de longitud',
    overWeightFee: 'Tarifa por exceso de peso',
    remoteFee: 'Tarifas remotas',
    feeCustoms: 'Tarifa de manejo logístico',
    feeFuel: 'Recargo por combustible',
    feeOperation: 'Tarifa de manejo',
    feeService: 'Tarifa de servicio',
    valueAddedTax: 'Impuesto al valor agregado',
    insuranceService: 'Prima',
    incrementAmount: 'Tarifa de servicio de valor agregado',
    lineDiscount: 'Descuento de línea',
    discount: 'Descuentos VIP',
    coupon: 'Seleccionar un cupón',
    passwordDiscount: 'Contraseña preferencial',
    orderPreDiscount: 'Descuento de mercancía',
    insuranceDiscount: 'Concesión de seguro',
    incrementDiscount: 'Oferta de servicio de valor agregado',
    preVolumeCal: 'Pesaje estimado',
    actDeposit: 'Flete después del pesaje',
    actDepositAmount: 'Pago final'
  },
  ratePaymentEnum: {
    name: 'Método de pago de impuestos',
    0: 'Pagado por el destinatario',
    1: 'GST/IVA de la plataforma',
    2: 'GST/IVA personal',
    3: 'IOSS de la plataforma',
    4: 'IOSS personal',
    5: 'GST/IVA de la empresa',
    6: 'GST/IVA de proveedores logísticos',
    7: 'IOSS de proveedores logísticos',
    8: 'Libre de impuestos'
  },
  bagStatusEnum: {
    0: 'Paquete normal',
    1: 'Devolución nacional',
    2: 'Devolución extranjera',
    3: 'Cancelar paquete',
    4: 'Paquete de pago adicional'
  },
  packageStatusEnum: {
    0: 'Esperando procesamiento',
    1: 'Procesando',
    2: 'Saliendo',
    3: 'Fuera de almacén',
    4: 'Empaquetado',
    5: 'Pesado',
    6: 'Se ha impreso el número de seguimiento',
    7: 'Acreditado',
    8: 'Entregado',
    9: 'El envío ha sido despachado',
    20: 'Paquete recibido'
  },
  declareEnum: {
    name: 'Método de declaración',
    0: 'Declaración del sistema',
    1: 'Decadencia personal'
  },
  backText: 'Volver',
  packageInformation: 'Información del paquete',
  parcelNumber: 'Número de paquete',
  noValidRule: 'Importes declarados no aprobados: {rule}',
  shippingAgree: 'Acuerdo de transporte de paquetes acbuy',
  haveDiscountCode: 'Tengo un código de descuento',
  inputDiscountCode: 'Introduzca el código de descuento aquí',
  expressNo: 'Número de seguimiento logístico'
};
