export default {
  payChannel: 'Canal de pagamento',
  cashierDesk: 'Caixa',
  billAddress: 'Endereço de cobrança',
  paySuccess: 'Pagamento bem-sucedido',
  payError: 'Pagamento falhou',
  harvestAddress: 'Endereço de entrega',
  orderAddress: 'Endereço de cobrança',
  selectAddress: 'Selecione o endereço de entrega',
  addOrderAddress: 'Por favor, adicione o endereço de cobrança primeiro',
  addSelectAddress: 'Por favor, selecione o endereço de entrega',
  noAddress: 'Nenhum endereço de entrega disponível',
  holder: {
    inputPayPwd: 'Por favor, insira a senha de pagamento'
  },
  transferTitle: 'Transferência bancária',
  changeAddress: 'Alterar endereço',
  editText: 'Editar',
  payWithbalance: 'Pagar com saldo acbuy',
  bankTransferRemittance: 'Transferência bancária',
  transferTips:
    '0 taxa de serviço na acbuy para transferências. Quanto maior o valor do pedido, mais economia!',
  pay: 'Ofertas de recarga',
  plsSetPsd: 'Defina por favor a senha do pagamento',
  thirdPayTips:
    'Os pagamentos estão disponíveis em todo o mundo e em várias moedas'
};
