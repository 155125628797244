import request from '@/utils/request';
import { userPrefix, payPrefix, commonPrefix } from './config';
import { objToFormdata } from '@/utils/searchImg';

// 用户注册
export const userRegister = params => {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    const res = await request.post(`${userPrefix}/api/register`, params);
    if (res.code === 200 || res.code === 0) {
      resolve(res.data);
    } else {
      reject(res.msg);
    }
  });
};

// 用户登录
export const userLogin = params => {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    const res = await request.post(`${userPrefix}/api/login`, params);
    if (res.code === 200 || res.code === 0) {
      resolve(res.data);
    } else {
      reject(res);
    }
  });
};

// 获取图形验证码
export const getVerifyCode = () => {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    const res = await request.get(`${userPrefix}/code`);
    if (res.code === 200 || res.code === 0) {
      resolve(res.data);
    } else {
      reject(res.msg);
    }
  });
};

// 激活邮件
export const activeEmail = params => {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    const res = await request.post(`${userPrefix}/api/active`, params);
    if (res.code === 200 || res.code === 0) {
      resolve(res.data);
    } else {
      reject(res.msg);
    }
  });
};

// 获取用户信息
export const getUserInfo = () => {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    const res = await request.get(`${userPrefix}/getUserInfo`);
    if (res.code === 200 || res.code === 0) {
      resolve(res.data);
    } else {
      reject(res.msg);
    }
  });
};
// 激活邮箱
export const activeVerifyEmail = params => {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    const res = await request.post(`${userPrefix}/api/active/email`, params);
    if (res.code === 200 || res.code === 0) {
      resolve(res.data);
    } else {
      reject(res.msg);
    }
  });
};

// 检验邮箱是否绑定其他账号
export const checkIsBindEmail = () => {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    const res = await request.get(`${userPrefix}/api/checkBindEmail`);
    if (res.code === 200 || res.code === 0) {
      resolve(res.data);
    } else {
      reject(res.msg);
    }
  });
};

// 注销账户
export const logoutAccount = params => {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    const res = await request.post(`${userPrefix}/api/destroyAccount`, params);
    if (res.code === 200 || res.code === 0) {
      resolve(res.data);
    } else {
      reject(res.msg);
    }
  });
};

// 获取最近登录历史
export const getHistoryLoginList = params => {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    const res = await request.get(`${userPrefix}/api/loginHistory/getList`, {
      data: params
    });
    if (res.code === 200 || res.code === 0) {
      resolve(res);
    } else {
      reject(res.msg);
    }
  });
};
// 获取邮箱验证码
export const getEmailVerifyCode = params => {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    const res = await request.post(`${userPrefix}/mail/code`, params);
    if (res.code === 200 || res.code === 0) {
      resolve(res.data);
    } else {
      reject(res.msg);
    }
  });
};

// 验证邮箱验证码
export const validateEmailVerifyCode = params => {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    const res = await request.post(`${userPrefix}/mailVerify`, params);
    if (res.code === 200 || res.code === 0) {
      resolve(res.data);
    } else {
      reject(res.msg);
    }
  });
};

// 获取新邮箱验证码
export const getNewEmailVerifyCode = params => {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    const res = await request.post(`${userPrefix}/newMail/code`, params);
    if (res.code === 200 || res.code === 0) {
      resolve(res.data);
    } else {
      reject(res.msg);
    }
  });
};

// 验证新邮箱验证码
export const validateNewEmailVerifyCode = params => {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    const res = await request.post(`${userPrefix}/newMail/verify`, params);
    if (res.code === 200 || res.code === 0) {
      resolve(res.data);
    } else {
      reject(res.msg);
    }
  });
};

// 修改用户邮箱邮箱
export const editUserEmail = params => {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    const res = await request.post(`${userPrefix}/api/mailEdit`, params);
    if (res.code === 200 || res.code === 0) {
      resolve(res.data);
    } else {
      reject(res.msg);
    }
  });
};

// 修改用户信息
export const setUserInfo = params => {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    const res = await request.post(`${userPrefix}/info/edit`, params);
    if (res.code === 200 || res.code === 0) {
      resolve(res.data);
    } else {
      reject(res.msg);
    }
  });
};

// 获取用户余额
export const getUserBalance = params => {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    const res = await request.get(`${payPrefix}/wallet/balance`, {
      data: params
    });
    if (res.code === 200 || res.code === 0) {
      resolve(res.data);
    } else {
      reject(res.msg);
    }
  });
};

// 创建充值余额订单
export const createRechargeBalanceOrder = params => {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    const res = await request.post(`${payPrefix}/recharge/create`, params);
    if (res.code === 200 || res.code === 0) {
      resolve(res.data);
    } else {
      reject(res.msg);
    }
  });
};

// 查询收件人列表
export const getRecipientList = params => {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    const res = await request.get(`${userPrefix}/address/list`, {
      data: params
    });
    if (res.code === 200 || res.code === 0) {
      resolve(res.data);
    } else {
      reject(res.msg);
    }
  });
};

// 删除收件人信息
export const delRecipient = params => {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    const res = await request.post(`${userPrefix}/address/remove`, params);
    if (res.code === 200 || res.code === 0) {
      resolve(res.data);
    } else {
      reject(res.msg);
    }
  });
};

// 新增收件人信息
export const addRecipient = params => {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    const res = await request.post(`${userPrefix}/address/add`, params);
    if (res.code === 200 || res.code === 0) {
      resolve(res.data);
    } else {
      reject(res.msg);
    }
  });
};

// 编辑收件人信息
export const editRecipient = params => {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    const res = await request.post(`${userPrefix}/address/edit`, params);
    if (res.code === 200 || res.code === 0) {
      resolve(res.data);
    } else {
      reject(res.msg);
    }
  });
};

// 获取余额交易记录
export const getBalanceTradeList = params => {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    const res = await request.get(`${payPrefix}/balanceDetail/user/list`, {
      data: params
    });
    if (res.code === 200 || res.code === 0) {
      resolve(res);
    } else {
      reject(res.msg);
    }
  });
};
// 获取流水账单记录
export const getBillTradeList = params => {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    const res = await request.get(`${payPrefix}/bills/getList`, {
      data: params
    });
    if (res.code === 200 || res.code === 0) {
      resolve(res);
    } else {
      reject(res.msg);
    }
  });
};

// 获取账单地址
export const getBillAddressList = params => {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    const res = await request.get(`${userPrefix}/api/billAddress/detail`, {
      data: params
    });
    if (res.code === 200 || res.code === 0) {
      resolve(res.data);
    } else {
      reject(res.msg);
    }
  });
};

// 新增账单地址
export const addBillAddress = params => {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    const res = await request.post(`${userPrefix}/api/billAddress`, params);
    if (res.code === 200 || res.code === 0) {
      resolve(res.data);
    } else {
      reject(res.msg);
    }
  });
};

// 修改账单地址
export const editBillAddress = params => {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    const res = await request.put(`${userPrefix}/api/billAddress`, params);
    if (res.code === 200 || res.code === 0) {
      resolve(res.data);
    } else {
      reject(res.msg);
    }
  });
};

// 删除账单地址
export const delBillAddress = id => {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    const res = await request.delete(`${userPrefix}/api/billAddress/${id}`);
    if (res.code === 200 || res.code === 0) {
      resolve(res.data);
    } else {
      reject(res.msg);
    }
  });
};

// 忘记密码 校验邮箱
export const pwdVerifyMail = params => {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    const res = await request.post(`${userPrefix}/resetMail`, params);
    if (res.code === 200 || res.code === 0) {
      resolve(res.data);
    } else {
      reject(res.msg);
    }
  });
};

// 忘记密码 重置密码
export const resetPassword = params => {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    const res = await request.post(`${userPrefix}/api/reset`, params);
    if (res.code === 200 || res.code === 0) {
      resolve(res.data);
    } else {
      reject(res.msg);
    }
  });
};

// 修改密码
export const editPassword = params => {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    const res = await request.post(`${userPrefix}/api/editPassword`, params);
    if (res.code === 200 || res.code === 0) {
      resolve(res.data);
    } else {
      reject(res.msg);
    }
  });
};

// 修改支付密码
export const editPayPassword = params => {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    const res = await request.post(`${payPrefix}/wallet/password`, params);
    if (res.code === 200 || res.code === 0) {
      resolve(res.data);
    } else {
      reject(res.msg);
    }
  });
};

// 获取公钥
export const getPublicKey = () => {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    const res = await request.get(`${userPrefix}/pubkey`);
    if (res.code === 200 || res.code === 0) {
      resolve(res.data);
    } else {
      reject(res.msg);
    }
  });
};

// 上传文件
export const uploadFile = params => {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    const res = await request.post(
      `${commonPrefix}/common/ossUpload`,
      objToFormdata(params)
    );
    if (res.code === 200 || res.code === 0) {
      resolve(res.data);
    } else {
      reject(res.msg);
    }
  });
};

// 获取默认收货地址
export const getDefaultAddress = params => {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    const res = await request.get(`${userPrefix}/address/choosedAdress`, {
      data: params
    });
    if (res.code === 200 || res.code === 0) {
      resolve(res.data);
    } else {
      reject(res.msg);
    }
  });
};
// 获取站内信列表
export const getMessageList = params => {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    const res = await request.get(`${userPrefix}/letter/getList`, {
      data: params
    });
    if (res.code === 200 || res.code === 0) {
      resolve(res);
    } else {
      reject(res.msg);
    }
  });
};
// 获取站内信统计
export const getMessageStatics = params => {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    const res = await request.get(`${userPrefix}/letter/letterCounts`, {
      data: params
    });
    if (res.code === 200 || res.code === 0) {
      resolve(res.data);
    } else {
      reject(res.msg);
    }
  });
};

/**
 * 批量阅读
 */
export const batchMessageRead = params => {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    const res = await request.post(
      `${userPrefix}/letter/batchRead/${params}`,
      {}
    );
    if (res.code === 200 || res.code === 0) {
      resolve(res.data);
    } else {
      reject(res.msg);
    }
  });
};
/**
 * 批量删除
 */
export const batchMessageDelete = params => {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    const res = await request.delete(
      `${userPrefix}/letter/batchRead/${params}`,
      {}
    );
    if (res.code === 200 || res.code === 0) {
      resolve(res.data);
    } else {
      reject(res.msg);
    }
  });
};
/**
 * 站内信列表
 */
export const getMessageReplyList = params => {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    const res = await request.get(`${userPrefix}/stationReply/getList`, {
      data: params
    });
    if (res.code === 200 || res.code === 0) {
      resolve(res.data);
    } else {
      reject(res.msg);
    }
  });
};
/**
 * 回复 站内信
 */
export const replyMessage = params => {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    const res = await request.post(`${userPrefix}/stationReply/reply`, params);
    if (res.code === 200 || res.code === 0) {
      resolve(res.data);
    } else {
      reject(res.msg);
    }
  });
};
/**
 * google 登录
 */
export const googleLogin = params => {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    const res = await request.post(`${userPrefix}/api/google_auth`, params);
    if (res.code === 200 || res.code === 0) {
      resolve(res.data);
    } else {
      reject(res.msg);
    }
  });
};
