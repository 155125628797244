export default {
  fillInOrder: 'Fill In Shopping Agent Order',
  inputTips: "(columns marked <span style='color: red'>*</span> are required)",
  process: {
    first: 'Fill in the order & submit',
    second: 'Pay for product & domestic delivery fee',
    three: 'Wait for your product to arrive at the warehouse'
  },
  productInfo: 'Product Information',
  productInfoTips:
    'All products available for shopping agent service displayed on acbuy are products retrieved from third-party shopping platforms and are not directly sold by acbuy. Thus, in case of any infringement issue involved by the aforesaid products, all liabilities incurred shall be borne by the corresponding sellers on the third-party platform while acbuy will not undertake any relevant, collateral or joint liability.',
  fees: 'Fees',
  feeStandard: 'Fee Standards',
  feeStandardTips:
    "For the shopping agent order you submit on this page, if the product is from third-party platforms like Kongfuzi Used Books, Xiaohongshu, and Youzan, etc., there is a chance that the product cannot be purchased. The product is associated with shipping risks, or an additional service fee might be charged. The actual service fee needs to be verified by the agent. For more details, please go to 'Help Center - Third-Party Shopping Agent Service Fee Standards'.",
  link: 'Link',
  name: 'Name',
  remark: 'Remark',
  unitPrice: 'Unit Price (CNY)',
  qty: 'Qty',
  goodsAttr: 'Specification of products',
  chinaPostage: 'Shipping Fee (CNY)',
  holder: {
    inputUrl: 'Please paste the product URL',
    inputName: 'Please enter product name here (Within 100 characters)',
    inputAttr: 'Please input specification of products',
    inputRemark:
      'If the item has several patterns, sizes, colors, etc., please specify what you need here based on the classification description on the product page. If you buy the same product but with different specifications, please separate the order.'
  },
  other: 'Other (optional)',
  productImg: 'Product Images',
  fareTip:
    'The domestic shipping fee to our warehouse (if unsure, please fill in 0, agent will verify and initiate a payment)'
};
