export default {
  myBalance: 'Mon solde',
  topUp: 'Recharger',
  pay: 'Recharger',
  balance: 'Solde',
  usableBalance: 'Solde disponible',
  inputBalance: 'Veuillez saisir le montant',
  payBalance: 'Montant de recharge',
  pecenter: 'Centre personnel',
  lang: 'Langue',
  cart: 'Panier',
  recipientName: 'Destinataire',
  phone: 'Téléphone',
  region: 'Région',
  address: 'Adresse détaillée',
  country: 'Pays ou région',
  province: 'Province',
  city: 'Ville',
  postcode: 'Code postal',
  action: 'Action',
  mobileSettings: 'Paramètres mobiles',
  name: 'Nom',
  add: 'Ajouter une adresse de facturation de carte de crédit',
  addSuccess: 'Ajout réussi',
  editSuccess: 'Modification réussie',
  editBtn: 'Modifier',
  edit: "Modifier l'adresse de facturation de la carte de crédit",
  del: 'Supprimer',
  setDefault: 'Définir par défaut',
  defaultAddress: 'Adresse par défaut',
  setDefaultAddress: 'Définir comme adresse de livraison par défaut',
  setSuccess: 'Configuration réussie',
  myAddress: 'Mes adresses',
  addAddress: 'Ajouter une nouvelle adresse',
  tradeTime: 'Heure de transaction',
  type: 'Type',
  incomeOrExpend: 'Revenus/Dépenses',
  accountBalance: 'Solde du compte',
  tradeNo: 'Numéro de transaction',
  remark: 'Remarque',
  tag: "Étiquette d'adresse",
  addBillAddress: 'Ajouter une adresse de facturation',
  deleteBillAddress: "Supprimer l'adresse de facturation",
  editBillAddress: "Modifier l'adresse de facturation",
  mergePay: 'Paiement groupé',
  username: "Nom d'utilisateur",
  gender: 'Genre',
  birthday: 'Date de naissance',
  avatar: 'Avatar',
  email: 'E-mail',
  site: 'Emplacement',
  myOrder: 'Commander',
  sideBar: {
    myAccount: 'Mon compte',
    addressManage: 'Gestion des adresses',
    myAsset: 'Mes actifs',
    billAddress: 'Adresse de facturation'
  },
  holder: {
    receiver: 'Le destinataire',
    firstName: 'Nom',
    name: 'Prénom',
    region: 'Veuillez sélectionner la province/ville/district/rue',
    address:
      "Veuillez saisir les informations détaillées de l'adresse, telles que la rue, le numéro, la résidence, le bâtiment, l'unité, etc.",
    phone: 'Le numéro de téléphone fixe ou mobile doit être rempli',
    input: 'Veuillez saisir',
    select: 'Veuillez sélectionner',
    tagHolder: 'Maximum 120 caractères (facultatif)',
    postCodeNoMatch: 'Le code postal ne correspond pas à la région'
  },
  forget: {
    accountSafe: 'Sécurité du compte',
    setPwd: 'Modifier le mot de passe',
    inputBindEmail: 'Veuillez saisir votre e-mail lié',
    inputVerifyCode: 'Veuillez saisir le code de vérification',
    inputPwd: 'Veuillez saisir le nouveau mot de passe'
  },
  accountInfo: {
    name: 'Compte',
    selfInfo: 'Informations personnelles',
    cardBillAddress: 'Adresse de facturation de la carte de crédit',
    shipAddress: 'Adresse de livraison',
    editUser: "Modifier les informations de l'utilisateur",
    inputCountry: 'Veuillez saisir votre pays',
    editEmail: "Modifier l'e-mail",
    editEmailHint:
      "(Recommandé uniquement lorsque l'e-mail est mal configuré ou présente des problèmes)",
    editEmailHint1:
      'Pour la sécurité de votre compte, vous devez d\'abord passer la vérification de sécurité avec "Code de vérification par e-mail + mot de passe de connexion"',
    editEmailHint2:
      'Pour la sécurité de votre compte, vous devez d\'abord passer la vérification de sécurité avec "Code de vérification par e-mail"',
    currentEmail: 'E-mail actuel',
    clickVerify: 'Envoyer',
    reGet: 'Renvoyer',
    nextStep: 'Étape suivante',
    notReGetCode: 'Renvoyer après',
    logoutAccount: 'Déconnexion du compte',
    emailNotEmpty: "L'e-mail ne peut pas être vide",
    logoutReason: 'Veuillez sélectionner la raison de la déconnexion',
    latelyLogin: 'Dernière connexion',
    moreRecord: "Plus d'enregistrements",
    visitRecord: 'Historique des visites',
    visitTime: 'Heure de visite',
    ipAndDevice: "Adresse IP et type d'appareil",
    editLoginPwdHint:
      "Un mot de passe sécurisé peut rendre votre compte plus sûr. Il est recommandé de changer régulièrement votre mot de passe et d'en définir un qui contient des chiffres et des lettres, et qui a plus de 6 caractères.",
    editPayPwdHint:
      "Le mot de passe requis lors de l'utilisation du solde acbuy pour payer, configurez le mot de passe de paiement pour garantir la sécurité du paiement.",
    editVerifyEmailHint:
      'Pour protéger la sécurité de votre compte, veuillez vérifier votre adresse e-mail.',
    payPwd: 'Mot de passe de paiement',
    editPayPwd: 'Modifier le mot de passe de paiement',
    setPayPwd: 'Définir un mot de passe de paiement',
    deleteAccount: 'Supprimer le compte'
  },
  logoutReasonEnum: {
    0: 'Problèmes de sécurité/confidentialité',
    1: 'Mauvais service client',
    2: 'Frais trop élevés',
    3: "Processus d'achat compliqué",
    4: 'Autres raisons'
  },
  tradeTypeEnum: {
    0: 'Revenus',
    1: 'Dépenses'
  },
  asset: {
    currentBalance: 'Solde actuel',
    canWithdrawBalance: 'Montant pouvant être retiré',
    withdraw: 'Retirer',
    serialNo: 'Numéro de série',
    amount: 'Montant',
    lossAmount: 'Montant dépensé',
    withdrawAmount: 'Montant du retrait',
    inputWithdrawAmount: 'Veuillez saisir le montant du retrait',
    withdrawSuccess: 'Demande de retrait soumise avec succès',
    transactionDate: 'Date de transaction',
    withdrawMethod: 'Méthode de retrait',
    availableAmount: 'Montant disponible',
    transactionNo: 'Numéro de transaction',
    withdrawMsg:
      'Remarque: le retrait du solde sera retourné via le canal d’origine. Le solde sera enregistré conformément aux dépenses historiques et retourné au compte de paiement pour les opérations de retrait.',
    transactionRecordsText: 'Historique des transactions du montant disponible',
    withdrawRecordText: 'Historique des retraits'
  },
  package: {
    packageInfo: 'Informations sur le colis',
    weightHaveUnit: 'Poids (g)',
    sizeHaveUnit: 'Taille (cm)',
    freight: "Frais d'expédition",
    fee: 'Frais de livraison',
    transportMode: 'Mode de livraison',
    allPackage: 'Tous les colis',
    myPackage: 'Le paquet'
  },
  packageStatusEnum: {
    0: 'Montant à reconstituer',
    1: 'En attente de paiement',
    2: 'En transit',
    3: 'Terminé',
    4: 'Annulé'
  },
  storage: {
    name: 'Entrepôt',
    warehouseTime: 'Temps de stockage en entrepôt',
    submitTransport: 'Soumettre pour livraison',
    lineCanUse: 'Pièces disponibles',
    commonImg: 'Photos de contrôle qualité',
    customPhoto: 'Photos personnalisées',
    extendCare: 'Stockage prolongé',
    extendHint:
      "Vos marchandises seront conservées dans l'entrepôt pour une période prolongée de (30*N) jours et la date d'expiration est",
    packMode: "Méthode d'emballage",
    retainPack: 'Emballage réservé',
    normalPack: 'Emballage par défaut',
    normalPackHint:
      "Les articles seront emballés avec leur emballage d'origine.",
    foldPack: 'Emballage plié',
    foldPackHint:
      "Pour les cartons non fixés avec de la colle, l'entrepôt les démontera, les pliera et les aplatira, puis les placera dans votre colis, ce qui peut réduire le volume du colis dans une certaine mesure. Mais il y a aussi des cas d'augmentation du volume du colis (lorsqu'il y a moins de produits, la longueur et la largeur du carton plié peuvent augmenter).",
    discardPack: "Jeter l'emballage",
    discardPackHint:
      "Après avoir jeté l'emballage, le vendeur peut ne pas accepter de service après-vente et l'emballage ne peut pas être retrouvé/ajouté.",
    photoTemplate: 'Modèle de photo'
  },
  operate: 'Opérer',
  editAddress: "Modifier l'adresse",
  underPaid: 'En attente de paiement',
  goTopay: 'Aller payer',
  viewRecord: "Voir l'historique",
  faq: 'FAQ',
  question1:
    'Q : Mon compte sera-t-il gelé si je soumets une demande de retrait ?',
  response1:
    'R: après avoir demandé un retrait, le compte gelera la partie du montant demandé afin de garantir que le montant remboursé correspond au montant que vous avez demandé',
  question2:
    'Q : "Terminé" dans les enregistrements de retrait signifie-t-il que le retrait des fonds a réussi ?',
  response2:
    "R : \"Terminé\" signifie qu'acbuy a organisé un remboursement, cependant, le temps qu'il faut pour arriver sur votre compte d'origine dépend du temps de traitement de la plateforme tierce.",
  question3: 'Q : Le retrait est-il basé sur le taux de change en temps réel ?',
  response3:
    'R : Non, le taux de change du retrait est basé sur le moment où les fonds ont été rechargés.',
  question4:
    'Q : Comment effectuer un retrait lorsque le solde du compte est différent du montant du retrait ?',
  response4:
    'R: lorsque le solde du compte est supérieur au montant disponible pour le retrait, vous pouvez appliquer en plusieurs fois jusqu’à ce que le solde du compte soit 0. Lorsque le solde du compte est inférieur au montant disponible pour le retrait, vous pouvez demander un retrait complet en une seule fois.',
  stillRecharge: 'Pas assez pour payer la commande, il faut encore recharger',
  amountDue: 'Montant dû',
  handingFee: 'Frais de traitement',
  coupon: 'Coupon',
  couponPlaceholder: 'Utiliser un code de coupon pour échanger des coupons',
  off: 'RÉDUCTION',
  minFee: 'Frais minimum',
  freightText: 'Fret',
  limitTo: 'Limité à',
  shippingUsage: "Utilisation de l'expédition",
  availableWarehouse: 'Entrepôt disponible',
  shippingFeeText: "Frais d'expédition (hors frais de dédouanement)",
  internationalParcel: 'Colis international',
  to: 'à',
  availableCoupons: 'Coupons disponibles',
  usedCoupons: 'Coupons utilisés',
  expiredCoupons: 'Coupons expirés',
  exchangeSuccess: 'Échange réussi !',
  myAffiliate: {
    name: 'Mon affiliation'
  },
  affiliateShare: "Part de l'Alliance",
  copyText: 'Copier',
  affiliateShareMsg:
    "Le paiement des frais d'expédition via le lien partagé vous rapportera des revenus de Commission",
  businessType: "Type d'entreprise",
  payWay: 'Types de transactions',
  bzOrderNo: 'Numéro AC',
  amountDueTooltip:
    "Il peut y avoir une différence d'environ 0,01 ou 0,02 en raison des calculs de conversion de taux de change",
  balanceStatement: 'Fluxo de saldo',
  billFlow: 'Bill'
};
