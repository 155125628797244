export default {
  abandonProduct: '放弃商品',
  abandonProductTips:
    '温馨提示：当您确认【放弃商品】后，我们将销毁您的商品，且无法恢复。该商品不会退回给卖家以获得退款。请谨慎操作。',
  finePhotos: '精细拍照',
  ordinaryPhotos: '普通拍照',
  expireText: '包裹即将到期',
  guaranteeService: '保障服务',
    noNeed: '不需要'
};
