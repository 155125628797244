export default {
  goodsStatusEnum: {
    '-1': 'Pendiente de pago',
    0: 'Pendiente de revisión',
    1: 'Pendiente de confirmación',
    2: 'Esperando pago adicional',
    3: 'Devolución de producto',
    4: 'Cambio de producto',
    5: 'Enviado',
    6: 'Envío separado',
    7: 'Procesamiento diferido',
    8: 'Recibido',
    9: 'En stock',
    10: 'Almacenado',
    11: 'Pedido cancelado',
    12: 'Producto destruido',
    13: 'Pedido vencido',
    14: 'Confirmación de información',
    15: 'Confirmación de compra',
    16: 'Devolución pendiente',
    17: 'Cambio pendiente',
    18: 'Procesando devolución',
    19: 'Procesando cambio',
    20: 'Devolución completada',
    21: 'Cambio completado',
    22: 'Pedido realizado',
    23: 'En proceso',
    24: 'El pedido ha sido cancelado',
    25: 'Pedido anormal',
    26: 'Devolución pendiente de reembolso',
    27: 'Cambio esperando envío del vendedor',
    28: 'Salida de almacén',
    29: 'Empaquetado y listo',
    30: 'Enviado al extranjero',
    31: 'Producto recibido',
    32: 'Pago rechazado',
    33: 'Auditoría de control de riesgos',
    34: 'El pedido ha sido revocado',
    35: 'Lista de pedidos congelados',
    36: 'Pago de pre-suscripción',
    37: 'Esperando ser enviado',
    38: 'Reembolso congelado',
    39: 'Producto rechazado',
    40: 'Rechazo completado',
    41: 'Excepción no en stock',
    42: 'Excepción en stock',
    43: 'Cancelar devolución',
    44: 'Cancelar cambio',
    45: 'Firmado y pendiente',
    46: 'Panamá pendiente de generación',
    47: 'Panamá pendiente de pago',
    48: 'Pago completado',
    49: 'Producto abandonado',
    50: 'Finalización obligatoria',
    51: 'Esperando aprobación del vendedor para devolución',
    52: 'Esperando aprobación del vendedor para cambio',
    53: 'Paquete devuelto inválido',
    54: '1688 pendiente de generación',
    55: '1688 pendiente de pago',
    56: 'Yino pendiente de generación',
    57: 'Yino pendiente de pago',
    58: 'Productos en transferencia'
  },
  appendStatusEnum: {
    0: 'Pendiente',
    1: 'En proceso',
    2: 'Cancelado',
    3: 'Completado',
    4: 'Devolución'
  }
};
