export default {
  useMoreAddress: '使用其他地址',
  hideAddress: '收起地址',
  productInventory: '商品清单',
  tag: '标签',
  retainTag: '保留标签',
  delTag: '去除标签',
  externalPack: '外部包装',
  packDetail: '包装详情',
  postLine: '寄送路线',
  selectLine: '选择线路',
  editLine: '修改线路',
  service: '服务',
  cancelServe: '取消服务',
  previewPackage: '预演包裹',
  postCountry: '寄送国家',
  packageNo: '包裹编号',
  deliver: '寄往',
  packageDetail: '包裹详情',
  addressInfo: '地址信息',
  voWeight: '体积重',
  firstWeight: '首重',
  continueWeight: '续重',
  actWeight: '实际称重',
  packageStatus: '包裹状态',
  freightDetail: '花费信息',
  declareCurrency: '申报币种',
  selfDeclare: '自主申报',
  packageDeclarePrice: '包裹申报总价格',
  youPackageProduct: '您包裹内的商品',
  editYourDeclare: '请编辑您的申报信息',
  startDeclare: '开始申报',
  declareType: '申报种类',
  productNo: '商品编号',
  declareProductPrice: '申报商品单价',
  declareTotalPrice: '申报商品总额',
  editDeclare: '修改申报',
  declareLinePriceHint: '当前线路申报总额最低：{min} 最高：{max}',
  classMaxNumHint: '单类数量已达上限 {max}',
  addDeclareProduct: '添加申报商品',
  plsConfirmInfo: '请确认信息',
  wipePackProduct: '去除包装商品',
  foldPackProduct: '折叠包装商品',
  wipeTagProduct: '去除标签商品',
  aboutCalcWeightExplain:
    '由于当前包裹未打包，现阶段不知道包裹真实的重量，因此我们只能靠几个现有参数进行包裹重量预估，以此收取押金，待包裹打包完毕得到真实重量后进行多退少补。',
  expressInfo: '物流信息',
  deliveryMode: '配送方式',
  targetCountry: '目标国家',
  cancelPackage: '取消包裹',
  cancelPackageHint: '确认要取消此包裹吗? 取消后将无法恢复。',
  isDelPackage: '确认要永久删除包裹吗?',
  delPackageHint: '永久删除后，您将无法再查看该包裹，请谨慎操作！',
  receivedTips: '您的包裹已签收,欢迎再次使用',
  packageQuestion: '包裹寄送过程发生了问题,您可以',
  afterSale: '申请售后',
  repair: '补款',
  ucan: '你可以',
  clickAdd: '点此添加',
  addCategoryTips: '以上没有您想申报的商品名',
  noCarrierLines: '没有可运送的寄送线路',
  confirmPackageTitle: '确认收到货了吗？',
  confirmPackageTips: '为了保障您的售后权益，请收到包裹确认无误后再确认收货',
  receiptSuccess: '签收成功',
  position: '定位',
  time: '时间',
  duty: '税号',
  inputTrueDuty: '请输入正确的税号',
  serveStatusEnum: {
    1: '定制照片',
    2: '延长保管期'
  },
  inputCareTime: '请选择保管时间',
  holder: {
    noInputSearch: '包裹号/商品编号',
    inputEditDeclareInfo: '请编辑您的申报信息',
    packageRemark: '如果您对包裹还有其他特备要求，请在这里备注'
  },
  calcFee: '预估费用',
  realFee: '实际费用',
  declare: {
    repeatDeclare: '重复申报类型',
    addDeclareType: '请添加申报类型'
  },
  feeEnum: {
    totalAmount: '预估押金',
    deposit: '预估费用',
    overLongFee: '超长费用',
    overWeightFee: '超重费用',
    remoteFee: '偏远费用',
    feeCustoms: '物流商处理费',
    feeFuel: '燃油附加费',
    feeOperation: '操作费',
    feeService: '服务费',
    valueAddedTax: '增值税',
    insuranceService: '保费',
    incrementAmount: '增值服务费',
    lineDiscount: '线路折扣',
    discount: 'vip折扣',
    coupon: '选择优惠券',
    passwordDiscount: '优惠口令',
    orderPreDiscount: '商品折扣',
    insuranceDiscount: '保险优惠',
    incrementDiscount: '增值服务优惠',
    preVolumeCal: '预估称重',
    actDeposit: '称重后运费',
    actDepositAmount: '最终需支付'
  },
  ratePaymentEnum: {
    name: '缴税方式',
    0: '收件人缴纳',
    1: '平台GST/VAT',
    2: '个人GST/VAT',
    3: '平台IOSS',
    4: '个人IOSS',
    5: '公司GST/VAT',
    6: '物流商GST/VAT',
    7: '物流商IOSS',
    8: '免税'
  },
  bagStatusEnum: {
    0: '正常包裹',
    1: '国内退包',
    2: '国外退包',
    3: '取消包裹',
    4: '补款包裹'
  },
  packageStatusEnum: {
    0: '待处理',
    1: '处理中',
    2: '出库中',
    3: '已出库',
    4: '已打包',
    5: '已称重',
    6: '已打印运单号',
    7: '已验证',
    8: '已交接',
    9: '已发货',
    20: '确认收货'
  },
  declareEnum: {
    name: '申报方式',
    0: '模糊申报',
    1: '自主申报'
  },
  backText: '返回',
  packageInformation: '包裹信息',
  parcelNumber: '包裹状态',
  noValidRule: '申报金额不通过规则: {rule}',
  shippingAgree: 'acbuy包裹运输协议',
  haveDiscountCode: '我有折扣码',
  inputDiscountCode: '在这里输入折扣码',
  expressNo: '物流单号'
};
