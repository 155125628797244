export default {
  payChannel: 'Les canaux de paiement',
  cashierDesk: 'Cassier',
  billAddress: 'Adresse de facturation',
  paySuccess: 'Paiement réussi',
  payError: 'Échec du paiement',
  harvestAddress: 'Adresse de livraison',
  orderAddress: 'Adresse de facturation',
  selectAddress: "Sélectionnez l'adresse de livraison",
  addOrderAddress: "Veuillez d'abord ajouter une adresse de facturation",
  addSelectAddress: 'Veuillez sélectionner une adresse de livraison',
  noAddress: "Pas d'adresse de livraison",
  holder: {
    inputPayPwd: 'Veuillez définir un mot de passe de paiement'
  },
  transferTitle: 'Virement bancaire',
  changeAddress: "Changement d'adresse",
  editText: 'Modifier',
  payWithbalance: 'Payer avec le solde acbuy',
  bankTransferRemittance: "Transfert d'argent par virement bancaire",
  transferTips:
    '0 frais de service sur acbuy pour les virements. Plus le montant de la commande est élevé, plus les économies sont importantes !',
  pay: 'Offres de recharge',
  plsSetPsd: 'Veuillez définir un mot de passe de paiement',
  thirdPayTips:
    'Les paiements peuvent être effectués dans le monde entier dans plusieurs devises'
};
