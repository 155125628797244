export default {
  storeTo: 'Armazém para onde enviar',
  weightHaveUnit: 'Peso (g)',
  productType: 'Categoria do item',
  long: 'Comprimento (cm)',
  width: 'Largura (cm)',
  height: 'Altura (cm)',
  seeDetail: 'Ver detalhes',
  queryTips:
    'Digite os parâmetros correspondentes para consultar o valor do frete!',
  carrier: 'Transportadora',
  carrierCode: 'Código da transportadora',
  product: 'Produto',
  productCode: 'Código do produto',
  totalfee: 'Custo total',
  billingWeight: 'Peso de cobrança',
  feeEstimation: 'Estimativa de custo',
  postFeeEstimation: 'Estimativa do frete',
  postFee: 'Custo de envio',
  postTime: 'Tempo estimado de envio',
  packUp: 'Recolher',
  startWeight: 'Peso inicial',
  endWeight: 'Peso final',
  calcExpression: 'Expressão de cálculo',
  baseFreight: 'Frete base',
  registrationFee: 'Taxa de registro',
  noSend: 'Produtos indisponíveis',
  canSend: 'Produtos disponíveis',
  priceFrom: 'Composição dos custos',
  itemLimit: 'Limite de itens',
  disReason: 'Motivo da indisponibilidade',
  BasicFreight: 'Fret de base',
  OperationFreight: `Frais d'opération`,
  RegistrationFreight: `Frais d'enregistrement`,
  IossFee: 'Frais IOSS',
  TariffFreight: 'Droits de douane',
  VATFregith: 'Taxe sur la Valeur Ajoutée (TVA)',
  AserviceFee: 'Frais de service supplémentaire',
  ExtraSizeFreight: 'Frais supplémentaires pour taille excessive',
  AdditionalDeliveryFeeForFemoteAreas:
    'Frais de livraison supplémentaires pour zones reculées',
  FuelSurcharge: 'Supplément de carburant',
  zoneCode: 'Zona ',
  amount: 'Preço ',
  rate: 'Taxa ',
  conditionExpression: 'Cálculo ',
  feeFactor: 'Fator de tarifa ',
  continuedPrice: 'Preço por Peso Continuado ',
  continuedWeight: 'Peso Continuado(g)',
  firstPrice: 'Preço pelo Primeiro Peso ',
  firstWeight: 'Primeiro Peso(g)',
  weightRangeStart: 'Peso Inicial(g)',
  weightRangeEnd: 'Peso Final(g)',
  holder: {
    toCountry: 'Escolher o destino',
    inputWeight: 'Digite o peso',
    selectCountry: 'Selecione o país',
    selectCarrier:
      'Selecione a transportadora/linha da transportadora/país da rota',
    selectCategory: 'Escolher a categoria do item'
  },
  typeDelivery: 'Tipos de Destinatários',
  shippingTime: 'Tempo estimado de envio',
  deliveryTips: 'Dicas de Entrega',
  estimatedShippingFee: 'Taxa de envio estimada',
  lineServiceTips:
    'Dica: Todos os serviços de logística para rotas que não são propriedade da acbuy são fornecidos por um fornecedor de logística terceirizado que irá ajustar os preços de frete de acordo com as condições do mercado. Em caso de alteração de preços, a acbuy irá notificá-lo com antecedência. Se tiver algum problema com o serviço de logística, a acbuy pode comunicar com o fornecedor de logística terceiras em seu nome.',
  logisticsStatement: '<Declaração de serviços logísticos>'
};
