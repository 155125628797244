import { defineStore } from 'pinia';
import { getCurrentUserInfo, getLevelConfig } from '@/apis/marketing';
import { useI18n } from 'vue-i18n';

export default defineStore('distributionStore', {
  state: () => {
    return {
      userInfo: {},
      levelData: []
    };
  },
  actions: {
    // 获取分销用户详情
    async getDistributionUserInfo() {
      try {
        const userInfo = await getCurrentUserInfo();
        this.userInfo = userInfo || {};
      } catch (error) {
        error;
      }
    },
    logout() {
      this.userInfo = {};
      this.levelData = [];
    },
    // 获取分销等级配置
    async getLevelData(t) {
      const result = [
        // {
        //   levelName: t('distribution.levelName'),
        //   indirectRatio: t('distribution.indirectExplain'),
        //   directRatio: t('distribution.directExplain'),
        //   freightAmount: t('distribution.experienceExplain')
        // }
      ];
      const levelData = await getLevelConfig();
      levelData.rows.length &&
        levelData.rows.forEach((item, index) => {
          item.startAmount = index === 0 ? 0 : item[index - 1]?.freightAmount;
          result.push(item);
        });
      this.levelData = result;
      this.levelData = this.levelData.reverse();
    }
  },
  persist: {
    key: 'distributionStore',
    storage: sessionStorage,
    paths: ['userInfo', 'levelData']
  }
});
