export default {
  lang: 'Language',
  currency: 'Currency',
  validGoodUrlFail: 'this is not a correct product link',
  product: 'products',
  productHolder: 'Enter Product Name/Link',
  // productHolder: 'Enter Product Name or Link/Shop Link/PI',
  shop: 'shop',
  shopHolder: 'Enter Shop Name/Shop Link',
  purchaseAgent: 'Purchase Agent',
  transport: 'Transport',
  manualOrdering: 'DIY Order',
  guideBook: 'GuideBook',
  help: 'Help',
  tools: 'Tools',
  customerService: 'Customer Service',
  purchaseGuide: 'Shopping Agent Guidance',
  payment: 'Payment',
  delivery: 'Delivery',
  afterSaleService: 'After Sales Service',
  insuranceCompensation: 'Insurance And Compensation',
  contactInfo: 'Contact Info',
  enterEmail: 'Enter your email',
  copyRight: 'Copyright©acbuy.com All Rights Reserved',
  aboutacbuy: 'About acbuy',
  contctTime: '9/7 (09:00-18:00, Beijing Time)',
  contactEmail: "Service Email: Support{'@'}acbuy.com",
  contactMarket: "Market Cooperation: Marketing{'@'}acbuy.com",
  contactCooperation: "Business Cooperation: Business{'@'}acbuy.com",
  tutorial: 'Tutorial',
  serviceFee: 'Service Fee',
  orderStatus: "Newcomer's Guide",
  returnRefunds: 'Returns And Refunds',
  topUp: 'Top Up',
  paypal: 'PayPal',
  internationalCreditCard: 'International Credit Card',
  telegraphicTransfer: 'Telegraphic Transfer',
  charges: 'Charges',
  mailRestrictions: 'Mail Restrictions',
  customsTaxation: 'Customs And Taxation',
  receiptInformation: 'Receipt Information',
  helpCenter: 'Help Center',
  contactUs: 'Contact Us',
  registerSuccess: 'Registration successful',
  registerMsg:
    'We have sent a verification email to your email. Please complete the verification in a timely manner, otherwise it will affect the normal use of your points.',
  continueShopping: 'Continue Shopping',
  continueLogin: 'Continue Login',
  verifyQuestion: 'Did you not receive the verification email?',
  verifyResopnseOne:
    '· acbuy verification email may be mistaken for spam, please check your spam folder for emails',
  verifyResopnseTwo:
    '· Delivery may sometimes be delayed by a few minutes, please be patient and wait',
  verifyResopnseThird:
    "· The verification email may be delayed for a few minutes. If you haven't received the email for a long time, you can check if it has been marked aspam or resend the verification email",
  agentGuide: 'Agent guide for newcomers',
  guideBookbtn: "Beginner's Guidebook",
  everyonePro:
    'Everyone can be a promoter, dual parcel commissions have been upgraded',
  joinAffiliate: 'Join Affiliate',
  joinacbuy: 'Join in acbuyDiscord to get more benefits',
  joinDiscord: 'Join Discord',
  productPpurchase: 'Product Purchase',
  shippingToWarehouse: 'Shipping to Warehouse',
  qualityAssurance: 'Quality Assurance',
  moreCostEffective: 'More cost-effective',
  productPpurchaseContent:
    'Product Purchase - you can buy any items easily from China. Just paste the product links to acbuy website and place an order. acbuy will purchase for you.',
  shippingToWarehouseContent:
    'Shipping to Warehouse - Shop from different sellers with our service and we will consolidate your purchase orders efficiently. All items will be shipped to acbuy warehouse and we will simplify the process for your convenience.',
  qualityAssuranceContent:
    'Quality Assurance - After your products arrive at the acbuy warehouse, we will do a comprehensive quality inspection on your products and send you the inspection feedback to give you better using experience.',
  moreCostEffectiveContent:
    "Concentrated transportation saves money - in your warehouse, you can freely select goods and ship them by one package. With acbuy's global shipping service, you can ship the goods to anywhere and enjoy more simple and reliable global transportation.",
  introduction: 'Shop Like A Millionaire',
  reviewOn: 'Reviews',
  productStoragePeriod: 'Product Storage Period',
  inspectionRules: 'Inspection Rules',
  afterSalesPolicy: 'After Sales Policy',
  insuranceAndCompensation: 'Insurance And Compensation',
  oneStopService: 'one-stop service',
  whatCustomersSay: 'what our customers say',
  customerSay1:
    'acbuy is the best shopping agent I have ever used - it is my first time to use acbuy and its products are cheaper than any other shopping agent, which is really amazing!',
  customerSay2:
    'After being a acbuy promoter, I received a cashback of 300 yuan. After that, I will get no less than 4% cashback on each order',
  customerSay3:
    'A very pleasant shopping experience - I have used acbuy to shop from China with good-quality products, great customer service and fast shipping.',
  customerSay4:
    'The best agent - the purchase was completed on the same day. It only took 7 days to ship the parcel to my home. This is the best agent currently',
  customerFirst: 'Customer First, Premium Service',
  customerServiceText: '9/7 Online Customer Service (09:00-18:00, Bj Time)',
  faqCategory: 'FAQ CATEGORIES'
};
