import { defineStore } from 'pinia'
import { addProductToCart, getCartProductList, getCartProductNum, delCartProduct, updateCartProductNum } from '@/apis/cart'
import { getProductPostFee } from '@/apis/goods'


// 购物车模块
export default defineStore('cartStore', {
  namespaced: true,
  state () {
    return {
      // 购物车商品列表
      list: [],
      productNum: 0,
      loading: false
    }
  },
  getters: {

  },
  actions: {
    // 添加商品到购物车
    async addProduct (params) {
      await addProductToCart(params)
      this.getCartProductNum()
    },
    // 更新购物车
    async updateCart (params = {}) {
      const res = await getCartProductList(params)
      this.list = res || []
    },
    // 获取购物车商品数量
    async getCartProductNum () {
      if (this.loading) return
      this.loading = true
      const res = await getCartProductNum()
      this.productNum = res
      this.loading = false
    },

    // 删除购物车数据
    async removeCartData (params) {
      await delCartProduct(params)
      this.updateCart()
      this.getCartProductNum()
    },
    // 更新商品个数
    async updateCartProductNum (product, params) {
      if (product.goodsId) {
        const postage = await getProductPostFee({
          itemId: product.goodsId.slice(2),
          source: product.goodsId.slice(0, 2),
          skuInfos: [
            {
              skuId: product.skuId ? product.skuId.slice(2) : '0',
              num: params.goodsNum
            }
          ]
        })
        params.postage = postage
      }
    
      await updateCartProductNum(params)
      this.updateCart()
      // this.list.forEach(shopItem => {
      //   shopItem.goods.forEach(item => {
      //     if (product.id === item.id) {
      //       item.goodsNum = params.goodsNum
      //     }
      //   })
      // })
    },

  }
})
