export default {
  addShopSuccess: 'Item added successfully',
  addShopFail: 'Failed to add item',
  getCartDataFail: 'Failed to get shopping cart items',
  hint: 'Tip',
  delConfirmProduct: 'Are you sure you want to delete this product?',
  confirm: 'Confirm',
  cancel: 'Cancel',
  productInfo: 'Product Details',
  unitPrice: 'Unit Price',
  num: 'Qty',
  subtotal: 'Subtotal',
  action: 'Action',
  productName: 'Product Name',
  splitProduct: 'Split Product',
  split: 'Split',
  allSelect: 'Select All',
  delProduct: 'Remove Selected',
  selected: 'Selected',
  piece: 'Item(s)',
  total: 'Total',
  productTotal: 'Excl. International Delivery Fee',
  orderPay: 'Checkout',
  orderTotal: 'Order Total',
  goCartPay: 'View Cart',
  moreView: 'More Cart Items',
  noSelectText: "Haven't selected any products yet",
  paySuccess: 'Payment Successful!',
  returnPayment: 'Return to continue payment',
  service: 'Service',
  goodsMinNumErrTips:
    'This product requires a minimum purchase quantity of {num}, you can choose different specifications according to your needs.',
  balckShop: 'The store is blacklisted',
  forbidBuy: 'Risk store, prohibited from purchasing',
  riskTip: 'Risk stores, please purchase with caution',
  riskReminder: 'Risk Reminder',
  blackenedShop: 'blackened Shop',
  riskProducts: 'Risk Products',
  riskMsg1:
    'There may be some risk associated with the products you submit. For your financial security, we are unable to provide you with online ordering services at this time. For more information, please contact Customer Service or Specialist Purchasing.',
  riskMsg2:
    'The products you submit may be at some risk of being shipped. For your financial security, we are temporarily unable to provide you with online ordering services. For more information, please contact online customer service!',
  riskMsg3:
    'The product you are purchasing may be a specialized or branded item. We lack the capability to authenticate its authenticity and can only offer procurement and basic quality inspection services. Any potential risks must be borne by you.',
  continueToBuy: 'Continue to buy others',
  buyOthers: 'Buy others',
  continuePurchasing: 'Continue purchasing'
};
