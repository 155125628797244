export default {
  addShopSuccess: '添加商品成功',
  addShopFail: '添加商品失败',
  getCartDataFail: '获取购物车商品失败',
  hint: '提示',
  delConfirmProduct: '确定要删除商品？',
  confirm: '确认',
  cancel: '取消',
  productInfo: '商品详情',
  unitPrice: '单价',
  num: '数量',
  subtotal: '小计',
  action: '操作',
  productName: '商品名称',
  splitProduct: '拆分商品',
  split: '拆分',
  allSelect: '全选',
  delProduct: '删除商品',
  selected: '已选择',
  piece: '件',
  total: '总计',
  productTotal: '不包括国际配送费',
  orderPay: '下单结算',
  orderTotal: '订单总计',
  goCartPay: '前往购物车结算',
  moreView: '更多购物车',
  noSelectText: '还没选择商品',
  paySuccess: '支付成功！',
  returnPayment: '返回继续支付',
  service: '服务',
  goodsMinNumErrTips:
    '此商品需要最少采购数量{num}，您可以根据需要选择不同的规格。',
  balckShop: '店铺为黑名单',
  forbidBuy: '风险商品，禁止购买',
  riskTip: '风险商品，请谨慎购买',
  riskReminder: '风险提示',
  blackenedShop: '黑名单店铺',
  riskProducts: '风险商品',
  riskMsg1:
    '您提交的产品可能存在一定的风险。为了您的资金安全，我们暂时无法为您提供在线订购服务。如需了解更多信息，请联系客户服务部或专家采购部。',
  riskMsg2:
    '您提交的产品可能存在一定的寄送风险。为了您的资金安全，我们暂时无法为您提供在线订购服务。如需了解更多信息，请联系在线客服！',
  riskMsg3:
    '您购买的产品可能是专业或品牌产品。我们缺乏验证其真实性的能力，只能提供采购和基本的质量检验服务。任何潜在风险都必须由您承担。',
  continueToBuy: '继续购买其它',
  buyOthers: '购买其它',
  continuePurchasing: '继续购物'
};
