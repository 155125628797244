export default {
  noSamePass:
    'Le mot de passe et la confirmation du mot de passe ne correspondent pas',
  loginSuccess: 'Connexion réussie',
  registerSuccess: 'Inscription réussie',
  isHaveAccount: 'Vous avez déjà un compte ?',
  login: 'Se connecter',
  logout: 'Se déconnecter',
  forgetPass: 'Mot de passe oublié ?',
  register: "S'inscrire",
  registered: 'Déjà inscrit,',
  emailTips: 'E-mail',
  password: 'Mot de passe',
  confirmPass: 'Confirmer le mot de passe',
  countryTips: 'Veuillez sélectionner votre pays',
  inviteCodeTips: "Veuillez entrer le code d'invitation (facultatif)",
  verifyEmpty: 'Les champs ne peuvent pas être vides',
  verifyEmail: 'Les informations saisies sont incorrectes',
  verifyPass:
    'Le mot de passe doit contenir entre 6 et 20 caractères, y compris des lettres et des chiffres',
  confirmText: "J'ai lu et j'accepte",
  agreementName: "《Accord d'inscription utilisateur PandaBuy》",
  verifyCode: 'Code de vérification e - mail',
  loginPwd: 'Mot de passe de connexion',
  editPwd: 'Modifier le mot de passe',
  changePwd: 'Changer le mot de passe',
  setPwd: 'Définir le mot de passe',
  and: 'et',
  privacyStatement: '《Déclaration de confidentialité》',
  confirm: 'Confirmer',
  cancel: 'Annuler',
  editSuccess: 'Modification réussie',
  email: 'E-mail',
  emailCode: "Code de vérification de l'e-mail",
  newPwd: 'Nouveau mot de passe',
  button: {
    search: 'Rechercher',
    query: 'VÉRIFIER MAINTENANT',
    reset: 'Réinitialiser',
    submit: 'Soumettre',
    submitOrder: 'Soumettre la commande',
    save: 'Enregistrer'
  },
  holder: {
    select: 'Veuillez sélectionner',
    startTime: 'Heure de début',
    endTime: 'Heure de fin',
    max: 'Maximum',
    min: 'Minimum',
    inputContent: 'Veuillez saisir le contenu',
    inputEmailAccount: 'Veuillez saisir une adresse e-mail valide'
  },
  back: 'Bienvenue de retour !',
  continueGoogle: 'Continuer avec Google',
  notAccount: "Vous n'avez pas de compte ?",
  signNow: 'Inscrivez-vous maintenant',
  termService: "Conditions d'utilisation",
  privacyPolicy: 'Politique de confidentialité',
  helpCenter: "Centre d'aide",
  readPrivacy: "J'ai lu et j'accepte",
  verifyAccept: "Vous n'avez pas encore accepté l'accord",
  accountSecurity: 'Sécurité du compte',
  loginNow: 'Se connecter maintenant',
  resetPsd: 'Réinitialiser le mot de passe',
  verifiedEmail: "Vérifier l'email",
  unverified: 'Non vérifié',
  emailVerification: "Vérification de l'e-mail",
  verifyMsg:
    "Votre e-mail n'a pas été vérifié, nous ne pouvons donc pas terminer l'opération d'achat pour le moment. Veuillez vérifier votre e-mail dans le centre personnel.",
  sendVerificationEmail: 'Envoyer un e-mail de vérification',
  linkHasExpired: 'Le lien a expiré !',
  hasSendEmail: 'E-mail de vérification envoyé !',
  shippingFeeEstimation: "Estimation des frais d'expédition",
  homeText: "Page d'accueil",
  loginBgMsg: "Rejoignez acbuy Discord pour obtenir plus d'avantages",
  registerTitleMsg: 'COMMENCEZ VOTRE VOYAGE',
  registerBgMsg:
    "Les nouveaux utilisateurs reçoivent des coupons d'expédition de 340$",
  announcement: 'Annonce',
  formAnnouncementMsg:
    "Un nouveau modèle pour le programme d'affiliation, modèle de commission de double colis, utilisez-le pour gagner de l'argent et économiser de l'argent",
  incorrectAmount: 'Le montant du retrait doit être supérieur à 0',
  uniOrderAgreeText:
    "Les liens que vous soumettez sur cette page appartiennent à des liens vers des produits tiers (tels que xianhuai, Little Red Book, bliberry, etc.) et acbuy ne peut que vous aider à trouver le marchand pour les consultations sur les produits, les achats et les services de livraison logistique tiers. acbuy ne peut pas faire la différence entre la qualité et l'authenticité des produits et vous devez assumer les risques associés avant de passer votre commande. Je suis d'accord pour l'augmenter",
  excludeFees:
    'Important: les frais de port internationaux seront calculés séparément.'
};
