import request from '@/utils/request';
import { goodsPrefix, userPrefix } from './config';

// 获取关键字商品列表
export const getGoodsList = params => {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    const res = await request.get(`${goodsPrefix}/product/api/item/search`, {
      data: params
    });
    if (res.code === 200 || res.code === 0) {
      resolve(res.data);
    } else {
      reject(res.msg);
    }
  });
};
// 获取图片商品列表
export const getImgGoodsList = params => {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    const res = await request.get(
      `${goodsPrefix}/product/api/item/image/search`,
      { data: params }
    );
    if (res.code === 200 || res.code === 0) {
      resolve(res.data);
    } else {
      reject(res.msg);
    }
  });
};
// 根据base64获取图片ID
export const getBase64ToImageId = params => {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    const res = await request.post(
      `${goodsPrefix}/product/api/item/image/upload`,
      params, { noParse: true }
    );
    if (res.code === 200 || res.code === 0) {
      resolve(res.data);
    } else {
      reject(res.msg);
    }
  });
};

// 获取商品详情
export const getProductDetail = params => {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    const res = await request.get(`${goodsPrefix}/product/api/item/detail`, {
      data: params
    });
    if (res.code === 200 || res.code === 0) {
      resolve(res.data);
    } else {
      reject(res.msg);
    }
  });
};

// 获取商铺商品详情
export const getShopProductList = params => {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    const res = await request.get(`${goodsPrefix}/product/api/shop/item/list`, {
      data: params
    });
    if (res.code === 200 || res.code === 0) {
      resolve(res.data);
    } else {
      reject(res.msg);
    }
  });
};

// 获取商铺详情
export const getShopInfo = params => {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    const res = await request.get(`${goodsPrefix}/shop/getStoreShop`, {
      data: params
    });
    if (res.code === 200 || res.code === 0) {
      resolve(res.data);
    } else {
      reject(res.msg);
    }
  });
};

// 立即购买商品
export const nowBuyProduct = params => {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    const res = await request.post(`${userPrefix}/api/cart/buy`, params);
    if (res.code === 200 || res.code === 0) {
      resolve(res.data);
    } else {
      reject(res.msg);
    }
  });
};
// 获取商品邮费
export const getProductPostFee = params => {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    const res = await request.post(`${goodsPrefix}/product/api/product/postFee`, params);
    if (res.code === 200 || res.code === 0) {
      resolve(res.data);
    } else {
      reject(res.msg);
    }
  });
};
// 获取商品分享链接
export const getProductShareLink = params => {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    const res = await request.get(`${goodsPrefix}/product/api/item/share`, {
      data: params
    });
    if (res.code === 200 || res.code === 0) {
      resolve(res.data);
    } else {
      reject(res.msg);
    }
  });
};
// 解析商品分享链接
export const parseProductShareLink = params => {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    const res = await request.get(`${goodsPrefix}/product/api/item/match`, {
      data: params
    });
    if (res.code === 200 || res.code === 0) {
      resolve(res.data);
    } else {
      reject(res.msg);
    }
  });
};
