<template>
  <ElConfigProvider :locale="localeMap[localeVal]">

    <router-view v-slot="{ Component }">
      <component :is="Component" />
    </router-view>
  </ElConfigProvider>
</template>

<script setup>
import { ElConfigProvider } from "element-plus";
import cn from "element-plus/dist/locale/zh-cn.mjs";
import en from "element-plus/dist/locale/en.mjs";
import { computed, ref, watch } from "vue";
import { useUserStore } from "@/stores";
import { useChat } from '@/utils/useChat'

const userStore = useUserStore();
const isLoadService = ref(false)
const localeMap = {
  cn,
  en,
};
const localeVal = computed(() => userStore.language);

userStore.getRate();

watch(() => userStore.isLogin, (isLogin) => {
  if (isLogin && !isLoadService.value) {
    isLoadService.value = true
    useChat({
      token: userStore.token,
      uid: userStore.profile.id,
      email: userStore.profile.email,
      guestName: userStore.profile.nickName || userStore.profile.userName,
      language: userStore.language,
      guestAvatar: userStore.profile.avatar
    })
  } else {
    // const script = document?.getElementById('script')
    // script && document.removeChild(script)
  }
}, { immediate: true })
</script>

<style lang="scss">
@import url("./styles/common.scss");
</style>
