export default {
  myBalance: 'Meu saldo',
  topUp: 'Recarregar',
  pay: 'Recarregar',
  balance: 'Saldo',
  usableBalance: 'Saldo disponível',
  inputBalance: 'Por favor, insira o valor',
  payBalance: 'Valor da recarga',
  pecenter: 'Centro Pessoal',
  lang: 'Idioma',
  cart: 'Carrinho',
  recipientName: 'Destinatário',
  phone: 'Telefone',
  region: 'Região',
  address: 'Endereço detalhado',
  country: 'País ou Região',
  province: 'Província',
  city: 'Cidade',
  postcode: 'Código postal',
  action: 'Ação',
  mobileSettings: 'Configurações móveis',
  name: 'Nome',
  add: 'Adicionar endereço de cobrança do cartão de crédito',
  addSuccess: 'Adicionado com sucesso',
  editSuccess: 'Editado com sucesso',
  editBtn: 'Editar',
  edit: 'Editar endereço de cobrança do cartão de crédito',
  del: 'Excluir',
  setDefault: 'Definir como padrão',
  defaultAddress: 'Endereço padrão',
  setDefaultAddress: 'Definir como endereço de entrega padrão',
  setSuccess: 'Configuração bem-sucedida',
  myAddress: 'Meus endereços',
  addAddress: 'Adicionar novo endereço',
  tradeTime: 'Hora da transação',
  type: 'Tipo',
  incomeOrExpend: 'Receita/Despesa',
  accountBalance: 'Saldo da conta',
  tradeNo: 'Número da transação',
  remark: 'Observação',
  tag: 'Etiqueta do endereço',
  addBillAddress: 'Adicionar endereço de cobrança',
  deleteBillAddress: 'Excluir endereço de cobrança',
  editBillAddress: 'Editar endereço de cobrança',
  mergePay: 'Pagamento combinado',
  username: 'Nome do Utilizador',
  gender: 'Gênero',
  birthday: 'Data de nascimento',
  avatar: 'Avatar',
  email: 'E-mail',
  site: 'Localização',
  myOrder: 'Ordem',
  sideBar: {
    myAccount: 'Minha conta',
    addressManage: 'Gerenciamento de endereços',
    myAsset: 'Meus ativos',
    billAddress: 'Endereço de cobrança'
  },
  holder: {
    receiver: 'Destinatário',
    firstName: 'Nome próprio',
    name: 'Nome',
    region: 'Por favor, selecione província/cidade/distrito/rua',
    address:
      'Por favor, insira informações detalhadas do endereço, como rua, número, bairro, edifício, unidade, etc.',
    phone: 'Número de telefone fixo ou celular deve ser preenchido',
    input: 'Por favor, insira',
    select: 'Por favor, selecione',
    tagHolder: 'Máximo de 120 caracteres (opcional)',
    postCodeNoMatch: 'O código postal não corresponde à região'
  },
  forget: {
    accountSafe: 'Segurança da conta',
    setPwd: 'Alterar senha',
    inputBindEmail: 'Por favor, insira seu e-mail vinculado',
    inputVerifyCode: 'Por favor, insira o código de verificação',
    inputPwd: 'Por favor, insira a nova senha'
  },
  accountInfo: {
    name: 'Conta',
    selfInfo: 'Informações pessoais',
    cardBillAddress: 'Endereço de cobrança do cartão de crédito',
    shipAddress: 'Endereço de entrega',
    editUser: 'Editar informações do usuário',
    inputCountry: 'Por favor, insira seu país',
    editEmail: 'Editar e-mail',
    editEmailHint:
      '(Recomendado apenas quando o e-mail foi configurado incorretamente ou apresenta problemas)',
    editEmailHint1:
      'Para a segurança da sua conta, você precisa passar pela verificação de segurança com "Código de verificação de e-mail + senha de login" primeiro',
    editEmailHint2:
      'Para a segurança da sua conta, você precisa passar pela verificação de segurança com "Código de verificação de e-mail" primeiro',
    currentEmail: 'E-mail atual',
    clickVerify: 'Enviar',
    reGet: 'Reenviar',
    nextStep: 'Próximo passo',
    notReGetCode: 'Reenviar após',
    logoutAccount: 'Encerrar sessão da conta',
    emailNotEmpty: 'O e-mail não pode estar vazio',
    logoutReason: 'Por favor, selecione o motivo do encerramento da sessão',
    latelyLogin: 'Login recente',
    moreRecord: 'Mais registros',
    visitRecord: 'Registros de visita',
    visitTime: 'Hora da visita',
    ipAndDevice: 'Endereço IP e tipo de dispositivo',
    editLoginPwdHint:
      'Uma senha segura pode tornar sua conta mais segura. Recomenda-se alterar sua senha regularmente e definir uma que inclua números e letras, com mais de 6 caracteres de comprimento.',
    editPayPwdHint:
      'A senha necessária ao usar o saldo acbuy para pagamento, configure a senha de pagamento para garantir a segurança do pagamento.',
    editVerifyEmailHint:
      'Para proteger a segurança da sua conta, verifique o seu email.',
    payPwd: 'Senha de pagamento',
    editPayPwd: 'Alterar senha de pagamento',
    setPayPwd: 'Definir a Senha de Pagamento',
    deleteAccount: 'Excluir conta'
  },
  logoutReasonEnum: {
    0: 'Problemas de segurança/privacidade',
    1: 'Mau atendimento ao cliente',
    2: 'Custos muito altos',
    3: 'Processo de compra complicado',
    4: 'Outras razões'
  },
  tradeTypeEnum: {
    0: 'Receita',
    1: 'Despesa'
  },
  asset: {
    currentBalance: 'Saldo atual',
    canWithdrawBalance: 'Valor que pode ser sacado',
    withdraw: 'Sacar',
    serialNo: 'Número de série',
    amount: 'Valor',
    lossAmount: 'Valor gasto',
    withdrawAmount: 'Valor do saque',
    inputWithdrawAmount: 'Por favor, insira o valor do saque',
    withdrawSuccess: 'Solicitação de saque enviada com sucesso',
    transactionDate: 'Data da transação',
    withdrawMethod: 'Método de saque',
    availableAmount: 'Valor disponível',
    transactionNo: 'Número da transação',
    withdrawMsg:
      'Nota: a retirada de saldo será devolvida através do canal original. O saldo será registrado de acordo com as despesas históricas e devolvido à conta de pagamento para a transação de saque.',
    transactionRecordsText: 'Registros de transações de valores disponíveis',
    withdrawRecordText: 'Registros de saques'
  },
  package: {
    packageInfo: 'Informações do pacote',
    weightHaveUnit: 'Peso (g)',
    sizeHaveUnit: 'Tamanho (cm)',
    freight: 'Custo de envio',
    fee: 'Taxa de envio',
    transportMode: 'Modo de entrega',
    allPackage: 'Todos os pacotes',
    myPackage: 'Parcela'
  },
  packageStatusEnum: {
    0: 'Montante a reconstituir',
    1: 'Aguardando pagamento',
    2: 'Em trânsito',
    3: 'Concluído',
    4: 'Cancelado'
  },
  storage: {
    name: 'Armazém',
    warehouseTime: 'Tempo de armazenamento',
    submitTransport: 'Enviar para entrega',
    lineCanUse: 'Peças disponíveis',
    commonImg: 'Fotos de controle de qualidade',
    customPhoto: 'Customized photos',
    extendCare: 'Armazenamento estendido',
    extendHint:
      'Seus produtos serão mantidos no armazém por um período estendido de (30*N) dias e a data de vencimento é',
    packMode: 'Método de embalagem',
    retainPack: 'Embalagem reservada',
    normalPack: 'Embalagem padrão',
    normalPackHint: 'Os itens serão embalados com sua embalagem original.',
    foldPack: 'Embalagem dobrada',
    foldPackHint:
      'Para caixas de papelão não fixadas com cola, o armazém as desmontará, dobrará e achatará, depois as colocará em seu pacote, o que pode reduzir o volume do pacote em certa medida. Mas também há casos de aumento do volume do pacote (quando há menos produtos, o comprimento e a largura da embalagem dobrada podem aumentar).',
    discardPack: 'Descartar embalagem',
    discardPackHint:
      'Após descartar a embalagem, o vendedor pode não aceitar qualquer pós-venda e a embalagem não pode ser encontrada/adicionada novamente.',
    photoTemplate: 'Modelo de foto'
  },
  operate: 'Operar',
  editAddress: 'Editar endereço',
  underPaid: 'Aguardando pagamento',
  goTopay: 'Ir para pagamento',
  viewRecord: 'Ver registro',
  faq: 'Perguntas frequentes',
  question1: 'P: Minha conta será congelada se eu solicitar um saque?',
  response1:
    'R: após a solicitação de saque, a conta congelará a parte do valor solicitado para garantir que o valor do reembolso seja o mesmo que você solicitou',
  question2:
    'P: "Concluído" nos registros de saque significa que o saque dos fundos foi bem-sucedido?',
  response2:
    'R: "Concluído" significa que a acbuy organizou um reembolso, no entanto, o tempo que leva para chegar à sua conta original depende do tempo de processamento da plataforma de terceiros.',
  question3: 'P: O saque é baseado na taxa de câmbio em tempo real?',
  response3:
    'R: Não, a taxa de câmbio do saque é baseada no momento em que os fundos foram recarregados.',
  question4:
    'P: Como fazer um saque quando o saldo da conta é diferente do valor do saque?',
  response4:
    'R: quando o saldo da conta for maior do que o valor disponível para retirada, você pode aplicar em parcelas até que o saldo da conta seja 0. Quando o saldo da conta for menor do que o valor disponível para retirada, você pode solicitar uma retirada total de uma só vez.',
  stillRecharge:
    'Não é suficiente para pagar o pedido, ainda é necessário recarregar',
  amountDue: 'Valor a pagar',
  handingFee: 'Taxa de manuseio',
  coupon: 'Cupom',
  couponPlaceholder: 'Resgatar cupões com códigos de cupão',
  off: 'DESCONTO',
  minFee: 'Taxa mínima',
  freightText: 'Frete',
  limitTo: 'Limite para',
  shippingUsage: 'Uso de envio',
  availableWarehouse: 'Armazém disponível',
  shippingFeeText: 'Taxa de envio (exclui taxa de desembaraço aduaneiro)',
  internationalParcel: 'Pacote internacional',
  to: 'para',
  availableCoupons: 'Cupons disponíveis',
  usedCoupons: 'Cupons usados',
  expiredCoupons: 'Cupons expirados',
  exchangeSuccess: 'Troca bem-sucedida!',
  myAffiliate: {
    name: 'Minha afiliação'
  },
  affiliateShare: 'avoir',
  copyText: 'cópia',
  affiliateShareMsg:
    'Pagamentos de envio feitos através de links compartilhados gerarão ganhos de comissão para você',
  businessType: 'Tipo de Negócio',
  payWay: 'Tipo de transacção',
  bzOrderNo: 'Número CA',
  amountDueTooltip:
    'Devido aos cálculos de conversão da taxa de câmbio, pode haver uma diferença de aproximadamente 0,01 ou 0,02',
  balanceStatement: 'Courant d’équilibre',
  billFlow: 'Bill'
};
