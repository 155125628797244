export default {
  noData: 'Aucune donnée supplémentaire',
  delConfirmTips:
    'Êtes-vous sûr de vouloir supprimer les données sélectionnées ?',
  delSuccess: 'Suppression réussie',
  tips: 'Conseils',
  add: 'Ajouter',
  edit: 'Modifier',
  del: 'Supprimer',
  editSuccess: 'Modification réussie',
  activeAccount: 'Activer le compte',
  mailActiveSuccess: 'Activation du compte réussie',
  back: 'Retour',
  goLogin: "Aller à l'identification",
  email: 'E-mail',
  emailSendSuccess: 'E-mail envoyé avec succès',
  paySuccess: 'Paiement réussi',
  action: 'Action',
  logoutSuccess: 'Déconnexion réussie',
  plsUpload: 'Veuillez télécharger',
  upload: 'Télécharger',
  file: 'Fichier',
  uploadFail: 'Échec du téléchargement',
  uploadSuccess: 'Téléchargement réussi',
  pay: 'Payer',
  day: 'Jour',
  servePrice: 'Frais de service',
  skillServePrice: 'Frais de service technique',
  unitMonth: 'Mois',
  num: 'Quantité',
  photo: 'Photo',
  product: 'Produit',
  sizeHaveUnit: 'Taille (cm)',
  weightHaveUnit: 'Poids (g)',
  fee: 'Frais',
  free: 'Gratuit',
  submitTime: 'Heure de soumission',
  weight: 'Poids',
  cancelReason: "Motif d'annulation",
  submitPackage: 'Soumettre le colis',
  submitSuccess: 'Soumission réussie',
  price: 'Prix',
  status: 'État',
  noProduct: 'Aucun produit',
  backEdit: 'Retour pour modification',
  errorHint: "Erreur d'interface, veuillez réessayer",
  reload: 'Actualiser',
  reloadPageTips:
    'Votre compte a été changé. Veuillez recharger la page pour obtenir des informations actualisées.',
  cancel: 'Annuler',
  noMsg: 'Aucune information',
  totalAmount: 'Montant total',
  see: 'Voir',
  confirm: 'Confirmer',
  send: 'Envoyer',
  nowNoInfo: "Pas d'information pour le moment",
  genderEnum: {
    0: 'Homme',
    1: 'Femme'
  },
  goEmailActiveTips:
    'Veuillez aller dans votre e-mail pour activer votre compte !',
  holder: {
    inputRemark: 'Veuillez saisir des remarques',
    inputExplainCancelReason: "Veuillez expliquer la raison de l'annulation",
    inputAddAddress: "Veuillez ajouter l'adresse de livraison"
  },
  acceptMsg:
    "Ce site Web stocke des cookies sur votre ordinateur pour vous offrir une meilleure expérience. Consultez votre politique de confidentialité pour plus d'informations.",
  accept: 'Accepter',
  refuse: 'Refuser',
  notice: 'Avis',
  noticeMsg: 'Ligne logistique mise en ligne, prix réduit de 10%',
  english: 'Anglais',
  spanish: 'Espagnol',
  french: 'Français',
  chinese: 'Chinois',
  copySuccess: 'Copie réussie',
  copyError: 'Échec de la copie',
  noAgainShow: 'Ne plus afficher',
  download: 'Télécharger',
  downloadSuccess: 'Téléchargement réussi',
  uploadImgTips: 'Veuillez télécharger le fichier JPG / PNG / WEBP / JPEG',
  noCheckAboutAgree: 'Non coché pour accepter les accords pertinents'
};
