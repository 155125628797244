export default {
  noData: 'No further data available',
  delConfirmTips: 'Are you sure you want to delete the selected data?',
  delSuccess: 'Successfully deleted',
  tips: 'Tips',
  add: 'Add',
  edit: 'Edit',
  del: 'Delete',
  editSuccess: 'Successfully modified',
  activeAccount: 'Activate account',
  mailActiveSuccess: 'Account activation successful',
  back: 'Return',
  goLogin: 'Go to login',
  email: 'Email',
  emailSendSuccess: 'Email sent successfully',
  paySuccess: 'Payment successful',
  action: 'Action',
  logoutSuccess: 'Logout successful',
  plsUpload: 'Please upload',
  upload: 'Upload',
  file: 'File',
  uploadFail: 'Upload failed',
  uploadSuccess: 'Upload successful',
  pay: 'Pay',
  day: 'Day',
  servePrice: 'Service charge',
  skillServePrice: 'Technical service fee',
  unitMonth: 'Month(s)',
  num: 'Quantity',
  photo: 'Photo',
  product: 'Product',
  sizeHaveUnit: 'Size (cm)',
  weightHaveUnit: 'Weight (g)',
  fee: 'Fee',
  free: 'Free',
  submitTime: 'Submission time',
  weight: 'Weight',
  cancelReason: 'Cancellation reason',
  submitPackage: 'Submit package',
  submitSuccess: 'Submission successful',
  price: 'Price',
  status: 'Status',
  noProduct: 'No products',
  backEdit: 'Return to edit',
  errorHint: 'Interface error, please try again',
  reload: 'Refresh',
  reloadPageTips:
    'You have switched an account. Please reload the page for updated information.',
  cancel: 'Cancel',
  noMsg: 'No information',
  totalAmount: 'Total Amount',
  see: 'View',
  confirm: 'Confirm',
  send: 'Send',
  nowNoInfo: 'No information at the moment',
  genderEnum: {
    0: 'Male',
    1: 'Female'
  },
  goEmailActiveTips: 'Please go to your email to activate your account!',
  holder: {
    inputRemark: 'Please enter remarks',
    inputExplainCancelReason: 'Please explain the reason for cancellation',
    inputAddAddress: 'Please add shipping address'
  },
  acceptMsg:
    'This website stores cookies on your computer to provide you with a better experience. See your privacy policy for more information.',
  accept: 'Accept',
  refuse: 'Refuse',
  notice: 'Notice',
  noticeMsg: 'New logistics line, 10% price reduction',
  english: 'English',
  spanish: 'Spanish',
  french: 'French',
  chinese: 'Chinese',
  copySuccess: 'Copy successful',
  copyError: 'Copy failed',
  noAgainShow: "Don't show again",
  download: 'Download',
  downloadSuccess: 'Download successful',
  uploadImgTips: 'Please upload JPG/PNG/WEBP/JPEG files',
  noCheckAboutAgree: 'Unchecked Agree to the agreement'
};
