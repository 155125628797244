export default {
  lang: '语言',
  currency: '货币',
  validGoodUrlFail: '这不是正确的商品链接',
  product: '商品',
  productHolder: '输入商品名称/链接',
  shop: '店铺',
  shopHolder: '输入店铺名称/店铺链接',
  purchaseAgent: '代购',
  transport: '运输',
  manualOrdering: '手动下单',
  guideBook: '指南手册',
  help: '帮助',
  tools: '工具',
  customerService: '客户服务',
  purchaseGuide: '代购指南',
  payment: '支付',
  delivery: '配送',
  afterSaleService: '售后服务',
  insuranceCompensation: '保险和赔偿',
  contactInfo: '联系信息',
  enterEmail: '输入您的邮箱',
  copyRight: '版权所有©acbuy.com',
  aboutacbuy: '关于acbuy',
  contctTime: '9/7（09:00-18:00，北京时间）',
  contactEmail: "客服邮箱：Support{'@'}acbuy.com",
  contactMarket: "市场合作：Marketing{'@'}acbuy.com",
  contactCooperation: "商务合作：Business{'@'}acbuy.com",
  tutorial: '教程',
  serviceFee: '服务费',
  orderStatus: '新手指南',
  returnRefunds: '退货和退款',
  topUp: '充值',
  paypal: 'PayPal',
  internationalCreditCard: '国际信用卡',
  telegraphicTransfer: '电汇',
  charges: '费用',
  mailRestrictions: '邮寄限制',
  customsTaxation: '海关和税收',
  receiptInformation: '收据信息',
  helpCenter: '帮助中心',
  contactUs: '联系我们',
  registerSuccess: '注册成功',
  registerMsg:
    '我们已向您的邮箱发送验证邮件。请及时完成验证，否则将影响您积分的正常使用。',
  continueShopping: '继续购物',
  continueLogin: '继续登录',
  verifyQuestion: '没有收到验证邮件？',
  verifyResopnseOne:
    '· acbuy验证邮件可能被误认为垃圾邮件，请检查您的垃圾邮件文件夹',
  verifyResopnseTwo: '· 发送可能会延迟几分钟，请耐心等待',
  verifyResopnseThird:
    '· 验证邮件可能会延迟几分钟。如果长时间未收到邮件，您可以检查是否被标记为垃圾邮件或重新发送验证邮件',
  agentGuide: '新手代购指南',
  guideBookbtn: '新手指南手册',
  everyonePro: '人人都可以成为推广员，双重包裹佣金已升级',
  joinAffiliate: '加入联盟',
  joinacbuy: '加入acbuy Discord获取更多福利',
  joinDiscord: '加入Discord',
  productPpurchase: '商品购买',
  shippingToWarehouse: '发货到仓库',
  qualityAssurance: '质量保证',
  moreCostEffective: '更具性价比',
  productPpurchaseContent:
    '商品购买 - 您可以轻松从中国购买任何商品。只需将商品链接粘贴到acbuy网站并下单，acbuy将为您购买。',
  shippingToWarehouseContent:
    '发货到仓库 – 通过我们的服务从不同卖家购物，我们将高效整合您的采购订单。所有商品都将运送到acbuy仓库，我们将简化流程，为您提供便利。',
  qualityAssuranceContent:
    '质量保证 - 当您的商品到达acbuy仓库后，我们将对您的商品进行全面的质量检查，并向您发送检查反馈，为您提供更好的使用体验。',
  moreCostEffectiveContent:
    '集中运输省钱 - 在您的仓库中，您可以自由选择商品并通过一个包裹发货。使用acbuy的全球运输服务，您可以将商品运送到任何地方，享受更简单可靠的全球运输。',
  introduction: '中国好物寄全球',
  reviewOn: '评论',
  productStoragePeriod: '产品储存期',
  inspectionRules: '检验规则',
  afterSalesPolicy: '售后政策',
  insuranceAndCompensation: '保险与赔偿',
  oneStopService: '一站式服务',
  whatCustomersSay: '用户评价',
  customerSay1:
    'acbuy是我用过的最好的购物代理——这是我第一次使用acbuy，它的产品比其他任何购物代理都便宜，这真是太棒了！',
  customerSay2:
    '在成为acbuy的推广人员后，我收到了300元的现金返还。之后，我将获得每笔订单不低于4%的现金返还',
  customerSay3:
    '非常愉快的购物体验-我使用acbuy从中国购物，产品质量好，客户服务好，发货快。',
  customerSay4:
    '最好的代理商-购买在同一天完成。把包裹送到我家只花了7天时间。这是目前最好的代理人',
  customerFirst: '客户至上，优质服务',
  customerServiceText: '9/7在线客服（09:00-18:00，北京时间）',
  faqCategory: 'FAQ类别'
};
