export const useChat = (userInfo) => {
  window.ezchat_guest_info = userInfo
  window.ezchat_style = {
    icon_module: 'none',//图标类型 classic默认图标 none无图标
    chat_style: {
      bottom: '100px',
      right: '80px'
    },
  }


  const script = document.createElement('script')
  script.id = 'script'
  const timeStamp = Date.now()
  script.src = `https://ezchat.live/client/ezchat-code/ezchat_code.js?timeStamp=${timeStamp}`
  document.body.appendChild(script)

  script.onload = () => {
    const icon = document.createElement('div')
    icon.innerHTML = `
    <div class="icon-wrapper">
      <svg style="display:block" class="ezchat_icon_open" t="1730637661533" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="1259" xmlns:xlink="http://www.w3.org/1999/xlink" width="30" height="30">
          <path d="M927.616 465.6C923.328 236.704 745.888 51.808 528 51.808h-32c-217.888 0-395.328 184.896-399.616 413.76C58.112 487.744 32 528.672 32 576v64c0 70.592 57.408 128 128 128s128-57.408 128-128v-64a128.064 128.064 0 0 0-126.784-127.872C173.728 262.688 318.912 115.808 496 115.808h32c177.12 0 322.272 146.88 334.784 332.32A128.064 128.064 0 0 0 736 576v64c0 57.792 38.72 106.176 91.392 122.016a337.504 337.504 0 0 1-191.936 124.48A79.712 79.712 0 0 0 560 832a80 80 0 1 0 0 160 79.68 79.68 0 0 0 67.872-38.112 402.432 402.432 0 0 0 278.24-193.6C955.968 742.816 992 695.776 992 640v-64c0-47.328-26.112-88.256-64.384-110.4zM224 576v64c0 35.296-28.704 64-64 64s-64-28.704-64-64v-64c0-35.296 28.704-64 64-64s64 28.704 64 64z m704 64c0 34.304-27.2 62.176-61.12 63.712l-2.496-1.184c-0.224 0.512-0.576 0.928-0.8 1.408A64 64 0 0 1 800 640v-64c0-35.296 28.704-64 64-64s64 28.704 64 64v64z" p-id="1260" fill="#ffffff"/></path>
      </svg>
      <svg style="display:none" class="ezchat_icon_close" width="30" height="30" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M18.601 8.39897C18.269 8.06702 17.7309 8.06702 17.3989 8.39897L12 13.7979L6.60099 8.39897C6.26904 8.06702 5.73086 8.06702 5.39891 8.39897C5.06696 8.73091 5.06696 9.2691 5.39891 9.60105L11.3989 15.601C11.7309 15.933 12.269 15.933 12.601 15.601L18.601 9.60105C18.9329 9.2691 18.9329 8.73091 18.601 8.39897Z" fill="white"></path>
      </svg>
    </div>
    
      `
    appendNode()
    function appendNode () {
      const parentNode = document.getElementById('service-icon')
      if (!parentNode) {
        setTimeout(() => {
          appendNode()
        }, 1000);
      } else {
        parentNode.appendChild(icon)
      }
    }
  }


}