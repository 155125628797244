export default {
  noSamePass: '密码和确认密码不一致',
  loginSuccess: '登录成功',
  registerSuccess: '注册成功',
  isHaveAccount: '已有账号？',
  login: '登录',
  logout: '登出',
  forgetPass: '忘记密码？',
  register: '注册',
  registered: '已经注册，',
  pwdErr: '您输入的密码有误.',
  emailTips: '邮箱',
  password: '密码',
  confirmPass: '确认密码',
  countryTips: '请选择所在国家',
  inviteCodeTips: '请输入邀请码（选填）',
  verifyEmpty: '输入信息不能为空',
  verifyEmail: '输入信息有误',
  verifyPass: '密码限6-20字符，必须包含字母和数字',
  confirmText: '我已阅读并同意',
  agreementName: '《PandaBuy 用户注册协议》',
  verifyCode: '验证码',
  loginPwd: '登录密码',
  editPwd: '修改密码',
  changePwd: '更改密码',
  setPwd: '设置密码',
  and: '和',
  privacyStatement: '《隐私声明》',
  confirm: '确认',
  cancel: '取消',
  editSuccess: '修改成功',
  email: '邮箱',
  emailCode: '邮箱验证码',
  newPwd: '新的密码',
  button: {
    search: '搜索',
    query: '立刻查询',
    reset: '重置',
    submit: '提交',
    submitOrder: '提交订单',
    save: '保存'
  },
  holder: {
    select: '请选择',
    startTime: '开始时间',
    endTime: '结束时间',
    max: '最高',
    min: '最低',
    inputContent: '请输入内容',
    inputEmailAccount: '请输入正确的邮箱地址'
  },
  back: '欢迎回来！',
  continueGoogle: '用Google继续',
  notAccount: '还没账户？',
  signNow: '立即注册',
  termService: '服务条款',
  privacyPolicy: '隐私政策',
  helpCenter: '帮助中心',
  readPrivacy: '我已阅读和接受',
  verifyAccept: '还没接受协议',
  accountSecurity: '账户安全',
  loginNow: '立即登录',
  resetPsd: '重置密码',
  verifiedEmail: '验证邮箱',
  unverified: '未验证',
  emailVerification: '邮箱验证',
  verifyMsg:
    '您的电子邮件尚未验证，因此我们暂时无法完成购物操作。请在个人中心验证您的电子邮件。',
  sendVerificationEmail: '发送验证电子邮件',
  linkHasExpired: '链接已经失效！',
  hasSendEmail: '已发送验证电子邮件！',
  shippingFeeEstimation: '运费估算',
  homeText: '首页',
  loginBgMsg: '加入acbuy Discord以获得更多福利',
  registerTitleMsg: '开始你的旅行',
  registerBgMsg: '新用户注册可获得340美元的运费优惠券',
  announcement: '公告',
  formAnnouncementMsg: '一种新的联盟计划模式，双包裹佣金模式，用它来赚钱和省钱',
  incorrectAmount: '提现金额要大于0',
  uniOrderAgreeText:
    '您在本页面提交的链接属于第三方产品链接（如咸鱼、小红书、哔哩哔哩等），acbuy只能帮助您找到该链接的商家进行产品咨询、购买和安排第三方物流配送服务。acbuy无法区分产品的质量和真实性，您需要在下单前承担相关风险。',
  excludeFees: '重要提示：国际运费将另行计算。'
};
