export default {
  addShopSuccess: 'Producto añadido con éxito',
  addShopFail: 'Fallo al añadir producto',
  getCartDataFail: 'Error al obtener productos del carrito',
  hint: 'Sugerencia',
  delConfirmProduct: '¿Está seguro de que desea eliminar el producto?',
  confirm: 'Confirmar',
  cancel: 'Cancelar',
  productInfo: 'Detalles del producto',
  unitPrice: 'Precio unitario',
  num: 'Cantidad',
  subtotal: 'Subtotal',
  action: 'Acción',
  productName: 'Nombre del producto',
  splitProduct: 'Dividir producto',
  split: 'Dividir',
  allSelect: 'Seleccionar todos',
  delProduct: 'Eliminar producto',
  selected: 'Seleccionado',
  piece: 'Unidad(es)',
  total: 'Total',
  productTotal: 'No incluye el costo de envío internacional',
  orderPay: 'Realizar pedido y pagar',
  orderTotal: 'Total del pedido',
  goCartPay: 'Ir al carrito para pagar',
  moreView: 'Ver más en el carrito',
  noSelectText: 'Todavía no ha seleccionado ningún producto',
  goodsMinNumErrTips:
    'Este artículo requiere una cantidad mínima de compra de {num}. Puede elegir diferentes especificaciones según sus necesidades.',
  balckShop: 'Las tiendas están en la lista negra',
  forbidBuy: 'Tiendas de riesgo, prohibidas las compras',
  riskTip: 'Tiendas de riesgo, por favor compre con precaución'
};
