export default {
  noSamePass: 'the password is different from the confirmation password',
  loginSuccess: 'login successfully',
  registerSuccess: 'Sign up successfully',
  isHaveAccount: 'Already Have An Account?',
  login: 'Log in',
  logout: 'Logout',
  forgetPass: 'Forgot your password?',
  register: 'Sign up',
  registered: 'Already Registered,',
  emailTips: 'Email',
  password: 'Password',
  pwdErr: 'Incorrect password.',
  confirmPass: 'Password Confirm',
  countryTips: 'Select your country',
  inviteCodeTips: 'Enter the invitation code (optional)',
  verifyEmpty: 'the input information cannot be empty',
  verifyEmail: 'incorrect input information',
  verifyPass:
    'the password is limited to 6 to 20 characters and must contain letters and numbers',
  confirmText: 'I have read and agree',
  agreementName: '《PandaBuy User Registration Agreement',
  verifyCode: 'Email verification code',
  loginPwd: 'Login Password',
  editPwd: 'Modify Password',
  changePwd: 'Change Login Password',
  setPwd: 'Set Password',
  and: 'and',
  privacyStatement: '《privacy statement》',
  confirm: 'Confirm',
  cancel: 'Cancel',
  editSuccess: 'Modify successfully',
  email: 'Email',
  emailCode: 'Email Verification',
  newPwd: 'New Password',
  button: {
    search: 'Search',
    query: 'CHECK NOW',
    reset: 'Reset',
    submit: 'Submit',
    submitOrder: 'Submit order',
    save: 'Save'
  },
  holder: {
    select: 'please select',
    startTime: 'Start Time',
    endTime: 'End Time',
    max: 'highest',
    min: 'minimum',
    inputContent: 'Please enter content',
    inputEmailAccount: 'Please enter the correct email account'
  },
  back: 'Welcome back!',
  continueGoogle: 'Continue with Google',
  notAccount: "Do'T Have An Account？",
  signNow: 'Sign up now',
  termService: 'Terms of Service',
  privacyPolicy: 'Privacy Policy',
  helpCenter: 'Help Center',
  readPrivacy: 'I Have Already Read And Accept',
  verifyAccept: 'have not readed the aggrement',
  accountSecurity: 'Account Security',
  loginNow: 'Log in now',
  resetPsd: 'Reset password',
  verifiedEmail: 'Verify Email',
  unverified: 'unverified',
  emailVerification: 'Email Verification',
  verifyMsg:
    'Your email has not been verified, so we are temporarily unable to complete the shopping operation. Please verify your email in the personal center.',
  sendVerificationEmail: 'Send verification email',
  linkHasExpired: 'The link has expired!',
  hasSendEmail: 'Have sent the verification email',
  shippingFeeEstimation: 'Shipping Fee Estimation',
  homeText: 'Página principal',
  loginBgMsg: 'Join to acbuy Discord to get more benefits',
  registerTitleMsg: 'START YOUR JOURNEY',
  registerBgMsg: 'Newcomers register to receive 340$ shipping coupons',
  announcement: 'Announcement',
  formAnnouncementMsg:
    'A new model for affiliate program, dual parcel commission model, use it to make money and save money',
  incorrectAmount: 'The withdrawal amount must be greater than 0',
  readPrivacy1: 'I have read',
  andAgree: 'and agreed to',
  aboveContent: 'the above content',
  purchasingNotice: 'Purchasing Notice',
  afterSaleService: 'after-sale service',
  uniOrderAgreeText:
    'The link you submitted on this page belongs to third-party product links (such as Xianyu, Xiaohongshu, Bilibili, etc.), and acbuy can only help you find the merchant of the link for product consultation, purchase, and arrange third-party logistics delivery services. acbuy cannot distinguish between product quality and authenticity, and you need to bear the related risks before placing an order.',
  excludeFees:
    'Important Notice: international shipping fees will be calculated separately.'
};
