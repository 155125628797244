export default {
  abandonProduct: 'Abandon Product',
  abandonProductTips:
    'Friendly reminder: When you confirm [Abandon Product], we will destroy your product and it cannot be recovered. The product will not be returned to the seller for a refund. Please proceed with caution.',
  finePhotos: 'Detailed photography',
  ordinaryPhotos: 'Ordinary picture',
  expireText: 'The package is about to expire',
  guaranteeService: 'Guarantee Service',
  
    noNeed: "Don't need"
};
