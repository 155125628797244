export default {
  noData: 'Não há mais dados',
  delConfirmTips: 'Tem certeza que deseja excluir os dados selecionados?',
  delSuccess: 'Exclusão bem-sucedida',
  tips: 'Dicas',
  add: 'Adicionar',
  edit: 'Editar',
  del: 'Excluir',
  editSuccess: 'Modificação bem-sucedida',
  activeAccount: 'Ativar conta',
  mailActiveSuccess: 'Conta ativada com sucesso',
  back: 'Voltar',
  goLogin: 'Ir para o login',
  email: 'E-mail',
  emailSendSuccess: 'E-mail enviado com sucesso',
  paySuccess: 'Pagamento bem-sucedido',
  action: 'Ação',
  logoutSuccess: 'Logout bem-sucedido',
  plsUpload: 'Por favor, faça o upload',
  upload: 'Fazer upload',
  file: 'Arquivo',
  uploadFail: 'Falha no upload',
  uploadSuccess: 'Upload bem-sucedido',
  pay: 'Pagar',
  day: 'Dia',
  servePrice: 'Taxa de serviço',
  skillServePrice: 'Taxa de serviço técnico',
  unitMonth: 'Mês(es)',
  num: 'Quantidade',
  photo: 'Foto',
  product: 'Produto',
  sizeHaveUnit: 'Tamanho (cm)',
  weightHaveUnit: 'Peso (g)',
  fee: 'Taxa',
  free: 'Grátis',
  submitTime: 'Hora de envio',
  weight: 'Peso',
  cancelReason: 'Motivo do cancelamento',
  submitPackage: 'Enviar pacote',
  submitSuccess: 'Envio bem-sucedido',
  price: 'Preço',
  status: 'Status',
  noProduct: 'Nenhum produto',
  backEdit: 'Voltar para editar',
  errorHint: 'Erro na interface, por favor tente novamente',
  reload: 'Recarregar',
  reloadPageTips:
    'Sua conta foi trocada. Por favor, recarregue a página para obter informações atualizadas.',
  cancel: 'Cancelar',
  noMsg: 'Sem informações',
  totalAmount: 'Valor total',
  see: 'Ver',
  confirm: 'Confirmar',
  send: 'Enviar',
  nowNoInfo: 'Sem informações no momento',
  genderEnum: {
    0: 'Masculino',
    1: 'Feminino'
  },
  goEmailActiveTips: 'Por favor, vá ao seu e-mail para ativar sua conta!',
  holder: {
    inputRemark: 'Por favor, insira observações',
    inputExplainCancelReason: 'Por favor, explique o motivo do cancelamento',
    inputAddAddress: 'Por favor, adicione o endereço de entrega'
  },
  acceptMsg:
    'Este site armazena cookies em seu computador para fornecer uma melhor experiência. Consulte sua política de privacidade para obter mais informações.',
  accept: 'Aceitar',
  refuse: 'Recusar',
  notice: 'Aviso',
  noticeMsg: 'Rota logística online, redução de preço de 10%',
  english: 'Inglês',
  spanish: 'Espanhol',
  french: 'Francês',
  chinese: 'Chinês',
  copySuccess: 'Cópia bem-sucedida',
  copyError: 'Falha na cópia',
  noAgainShow: 'Não mostrar novamente',
  download: 'Baixar',
  downloadSuccess: 'Download bem-sucedido',
  uploadImgTips: 'Envie arquivos JPG/PNG/WEBP/JPEG',
  noCheckAboutAgree: 'Aceptar el acuerdo relevante sin marcar'
};
