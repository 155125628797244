export default {
  goodsStatusEnum: {
    '-1': 'Unpaid',
    0: 'Under review',
    1: 'Pending confirmation',
    2: 'Waiting for replenishment',
    3: 'Return goods',
    4: 'Exchange goods',
    5: 'Delivered',
    6: 'Separate shipment',
    7: 'Deferred processing',
    8: 'Signed for',
    9: 'In stock',
    10: 'Stored',
    11: 'Cancelled order',
    12: 'Destruction goods',
    13: 'Overdue order',
    14: 'Information confirmation',
    15: 'Purchase confirmation',
    16: 'Return pending',
    17: 'Replacement pending',
    18: 'Return processing',
    19: 'Replacement processing',
    20: 'Return finished',
    21: 'Replacement finished',
    22: 'On order',
    23: 'Being processed',
    24: 'Order cancelled',
    25: 'Abnormal order',
    26: 'Returns pending refund',
    27: 'Replacement waiting for seller to deliver',
    28: 'Out of warehouse',
    29: 'Out and packed',
    30: 'Send overseas',
    31: 'Received goods',
    32: 'Order refused payment',
    33: 'Risk control audit',
    34: 'order withdrawn',
    35: 'List of frozen orders',
    36: 'Pre-subscription payment',
    37: 'Waiting to be shipped',
    38: 'Refund frozen',
    39: 'Rejected goods',
    40: 'Rejection completed',
    41: 'Exception not in stock',
    42: 'Exception in stock',
    43: 'Cancel return',
    44: 'Cancel exchange',
    45: 'Signed and pending',
    46: 'Panama waiting to be generated',
    47: 'Panama waiting to be paid',
    48: 'Payment completed',
    49: 'Abandon product',
    50: 'Mandatory completion',
    51: 'Waiting for seller to approve return',
    52: 'Waiting for seller to approve exchange',
    53: 'Returned package invalid',
    54: '1688 waiting to be generated',
    55: '1688 waiting to be paid',
    56: 'Yino waiting to be generated',
    57: 'Yino waiting to be paid',
    58: 'Commodities in transfer'
  },
  appendStatusEnum: {
    0: 'Pending',
    1: 'Processing',
    2: 'Cancelled',
    3: 'Completed',
    4: 'Returned'
  }
};
