export default {
  confirmOrder: 'Confirmar pedido',
  createSuccess: 'Pedido creado con éxito',
  orderPrice: 'Precio total del pedido',
  otherAction: 'Otras acciones',
  orderStatus: 'Estado del pedido',
  afterSales: 'Atención posteriores a la venta',
  allSelect: 'Seleccionar todo',
  orderNo: 'Número de pedido',
  createTime: 'Hora de creación',
  productRemark: 'Información de nota del producto',
  productRemarkHolder:
    'Comentarios para el agente de compra, si no hay requisitos especiales de compra, no lo rellene.',
  orderDetail: 'Detalles del pedido',
  goPay: 'Pagar ahora',
  cancelpay: 'Cancelar pago',
  cancelPay: '¿Cancelar el pago?',
  cancelOrder: 'Cancelación del pedido?',
  cancelorder: 'Cancelación del pedido',
  cancelOrderProduct: 'Cancelar el producto?',
  cancelorderProduct: 'Cancelar el producto',
  cancelPayResultHint:
    'Después de la cancelación, el pago no se puede restaurar, por favor seleccione el motivo de la cancelación del pago',
  cancelOrderResultHint:
    'Después de la cancelación, el pedido no se puede restaurar, por favor ingrese el motivo de la cancelación del pedido',
  delOrder: 'Eliminar pedido',
  delOrderHint:
    '¿Está seguro de que desea eliminar el pedido? Si la mercancía ha sido reembolsada, El artículo no será devuelto al vendedor para un reembolso',
  cancelSuccess: 'Cancelación exitosa',
  delSuccess: 'Eliminación exitosa',
  orderBin: 'Estación de reciclaje',
  backOrder: 'Volver a mi pedido',
  restoreOrder: 'Restaurar pedido',
  remark: 'Observaciones',
  all: 'Todo',
  productName: 'Nombre del producto',
  submitTime: 'Hora de envío',
  orderConsult: 'Consulta de pedido',
  appendService: 'Servicio adicional',
  iKnow: 'Entiendo',
  cancelReturnBag: 'Cancelar devolución y cambio',
  isCancelApply: '¿Está seguro de que desea cancelar la solicitud?',
  goPay2: 'Ir a realizar el pago adicional',
  backTax: 'Pago adicional',
  cancelBackTax: 'Cancelar pago adicional',
  productAttr: 'Atributos del producto',
  backTaxType: 'Tipo de pago adicional',
  taxPrice: 'Precio del pago adicional',
  productBackTax: 'Pago adicional del producto',
  freightBackTax: 'Pago adicional del flete',
  productTaxPrice: 'Precio del pago adicional del producto',
  freightTaxPrice: 'Precio del pago adicional del flete',
  totalTaxPrice: 'Precio total del pago adicional',
  productStatus: 'Estado del producto',
  isNeedTax: '¿Necesita un pago adicional?',
  yes: 'Sí',
  no: 'No',
  iThinkAbout: 'Lo pensaré',
  revokeCheckedProduct: 'Anular la selección del producto',
  taxTips1:
    'Por favor, realice el [Pago adicional] o [Cancele] lo antes posible para no afectar la compra de otros productos en el pedido.',
  cancelTaxTips1:
    'Si no desea realizar el pago adicional, no podremos comprar el producto que requiere un pago adicional.',
  cancelTaxTips2:
    'Los productos marcados se cancelarán después de la revocación, y el costo del producto se reembolsará a su cuenta.',
  cancelTaxTips3:
    'Los productos no marcados se mantendrán y continuaremos comprándolos por usted.',
  cancelReason: {
    0: 'No quiero comprarlo',
    1: 'La información es incorrecta',
    2: 'El vendedor está fuera de stock',
    3: 'No puedo pagar',
    4: 'Otras razones'
  },
  hint: 'Sugerencia',
  orderStatusEnum: {
    0: 'Pendiente de pago',
    1: 'No procesado',
    2: 'En revisión',
    3: 'Pedido transferido',
    4: 'Pedido cancelado',
    5: 'Pago adicional de flete',
    6: 'En revisión de control de riesgos',
    7: 'Pedido revocado',
    8: 'Pago en progreso',
    9: 'Completado'
  },
  returnGood: {
    name: 'Devolución/Cambio',
    selectType: 'Seleccionar tipo',
    returnGood: 'Devolución',
    returnDayTips: 'Generalmente toma 3-7 días',
    changeGood: 'Cambio',
    changeDayTips: 'Generalmente toma 5-10 días',
    returnReasonEnum: {
      0: 'Compré equivocado/No me gusta',
      1: '¿El vendedor envió el producto incorrecto?',
      2: 'Problema de calidad del producto',
      3: 'No se envió en el tiempo acordado',
      5: 'Otras razones'
    },
    returnReasonSelect: 'Seleccione el motivo de la devolución',
    returnExplain: 'Instrucciones de devolución',
    residueReturnNumTips:
      'Derechos de devolución de servicio gratuito restantes este mes',
    returnTips:
      'Verificación final de la devolución debido al vendedor o a la plataforma, acbuy le reembolsará el costo de envío que haya asumido.',
    changeTips:
      'Para devoluciones o cambios debido a razones del vendedor, es posible que aún deba asumir parte del costo de envío del cambio.',
    returnTip4: 'Recordatorio amistoso',
    returnTip5:
      'El servicio de devolución y cambio establecerá un número de veces de servicio de operación gratuito. (Cada usuario puede solicitar la devolución o cambio de mercancías sin cargo por servicio de operación por primera vez cada mes, mientras que el costo de envío generado por la devolución o cambio de mercancías se negocia entre el agente y el vendedor y es recaudado por acbuy.) Tarifa de servicio de devolución y cambio de acbuy: 5 yuanes/pedido',
    returnTip10:
      'Si la devolución o cambio se debe a la responsabilidad del agente de compra, del vendedor o de la plataforma, el sistema le reembolsará esta tarifa de operación de 5 yuanes a su cuenta de saldo de acbuy.'
  },
  holder: {
    selectCancelReason: 'Por favor, seleccione el motivo de la cancelación',
    inputOtherReason: 'Por favor, ingrese el motivo',
    inputSearchHint: 'Nombre / número de pedido / número de producto.',
    inputCancelReason:
      'Por favor, ingrese el motivo de la cancelación del pedido'
  },
  selectProducts: 'Seleccionar productos',
  confirmStepOrder: 'Confirmar pedido',
  PayForProducts: 'Pagar por los productos',
  sendingPackages: 'Envío de paquetes',
  submitDelivery: 'Enviar para entrega',
  readAgreeText: 'He leído y acepto',
  disclaimer: 'El descargo de responsabilidad',
  orderNote:
    'Nota: Después de completar el pago, deberá enviar el paquete para entrega internacional una vez que el pedido llegue y se almacene en el almacén.',
  reminder: 'Recordatorio',
  disclaimer1: 'Descripción de los artículos prohibidos',
  disclaimer2: 'Cláusulas de servicio',
  disclaimer3: 'Política de devolución',
  customPhotosTips:
    'Una foto solo disponible para fotografiar una mercancía un detalle a petición',
  backCartEdit: 'Volver al carrito modificar',
  customPhotosReminderLabel: 'Recordatorio de buena voluntad',
  customPhotosReminder:
    'Una foto solo proporciona un requisito de detalle para tomar una foto de un producto',
  packageAgreeTip1:
    '1. los retrasos, daños, pérdidas, impuestos, confiscaciones y otras incertidumbres relacionadas con los servicios de los proveedores de logística están más allá de nuestro control y no asumiremos todas las responsabilidades resultantes. Sin embargo, haremos todo lo posible para recordarle que piense dos veces antes de comprar, ayudarlo a resolver los problemas de los proveedores de logística y compensarle de acuerdo con la política post - venta proporcionada por los proveedores de logística.',
  packageAgreeTip2:
    '2. le recomendamos que declare su paquete con precisión. Si su paquete es inspeccionado al azar durante el despacho de aduanas en el país de destino, puede tener que pagar algunos impuestos. Si recibe un aviso de impuestos, pague impuestos en consecuencia, de lo contrario puede enfrentar las consecuencias de pagar altas tarifas de devolución, destrucción de paquetes, etc.',
  applyRefund: 'La solicitud de restitución',
  refundReminder:
    'Consejos cálidas: después de solicitar un reembolso, el comprador irá a verificar el estado de la mercancía, no puede cancelar la solicitud de reembolso antes de verificar.',
  inputApplyReason: 'Por favor ingrese el motivo de su solicitud',
  orderConfirm: 'Confirmar pedido',
  replyMsg: 'La respuesta de la información',
  replyMsgTips:
    'La información de la observación que usted envía, nosotros notificará al comprador para verificar para usted.'
};
