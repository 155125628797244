export default {
  agreeTitle:
    'Acuerdo sobre los servicios de marketing a través de "acbuy Affiliate" en el sitio web de acbuy',
  agreementText: `Bienvenido a registrarse en el sitio web de acbuy y utilizar sus servicios de marketing de "acbuy Affiliate". Este Acuerdo se celebra entre los socios de "acbuy Affiliate" (en adelante denominado "Parte B") y acbuy (en adelante denominado "Parte A") (incluyendo pero no limitado a www.acbuy.com, aplicación móvil de acbuy, etc., en adelante denominado "Sitio web de la Parte A") con respecto a los asuntos relacionados con los servicios de marketing de "acbuy Affiliate". La Parte B deberá leer cuidadosamente todos los textos del presente, especialmente las partes en negrita, antes de utilizar los servicios de marketing de "acbuy Affiliate" en el sitio web de acbuy, y al hacer clic en el botón "Lo he leído y estoy de acuerdo", se considera que la Parte B ha aceptado y acordado cumplir con las especificaciones del presente. El Acuerdo constituirá el acuerdo entre la Parte B y acbuy y tendrá fuerza legal. acbuy tendrá el derecho de modificar unilateralmente los artículos del presente basándose en sus necesidades comerciales, y acbuy publicará dichas modificaciones en el Sitio web de la Parte A inmediatamente en caso de que se realicen modificaciones a los artículos del presente, y la Parte B deberá dejar de usar inmediatamente los servicios de marketing en el canal de "acbuy Affiliate" en caso de que la Parte B no esté de acuerdo con las modificaciones a los textos del presente, o de lo contrario, se considerará que la Parte B está de acuerdo con las modificaciones a los textos del presente.
Artículo 1 Definiciones 1. Consumidor: significa el miembro de acbuy que accede al Sitio web de la Parte A para registrarse`,
  dashboard: 'Panel de control',
  hi: 'Hola, ',
  inviteFriends: 'Invitar amigos',
  inviteFriendsTips:
    '¡Invita a tus amigos a acbuy y gana más dinero en efectivo! Invita más, gana más, sin límites.',
  inviteFriendsTips1:
    'Se invita a tres cuentas activas a obtener derechos de venta y ganar Comisiones.',
  totalInvite: 'Total de usuarios invitados',
  myTeam: 'Mi equipo',
  extractMoney: 'Comisión liquidada',
  noExtractMoney: 'Comisión no liquidada',
  inviteTitle: 'Invitar a mis amigos',
  copyCode: 'Copiar código de invitación',
  poster: 'Póster exclusivo',
  myLevel: 'Mi prerrogativa',
  youLevel: 'Tu nivel actual',
  commissionRate: 'Tasa de comisión',
  experienceValue: 'Valor de experiencia ({num}/{total})',
  levelDesc: 'Descripción del nivel',
  invite: 'Invitar',
  revenueDetails: 'Detalle de invitación',
  registerTime: 'Tiempo de registro',
  inviteType: 'Relación de invitación',
  userMail: 'Correo del usuario',
  bonus: 'Bono',
  myBonus: 'Mi bono',
  myBalance: 'Mi saldo',
  applyWithdraw: 'Solicitar retiro',
  withdrawTips:
    'Consejo: Debido a la tarifa de transacción involucrada en el retiro, se recomienda retirar una cantidad superior a ¥500.00',
  bank: 'Banco',
  paypalAccount: 'Cuenta PayPal',
  bankAccount: 'Cuenta bancaria',
  bankName: 'Nombre del banco',
  withdrawAmount: 'Cantidad a retirar ({unit})',
  bonusDetail: 'Detalle del bono',
  directCommission: 'Detalles de comisión directa',
  indirectCommission: 'Detalles de comisión indirecta',
  tradeTime: 'Tiempo de negociación',
  packNo: 'Número de paquete',
  actCommission: 'Comisión real',
  settlementTime: 'Tiempo de liquidación',
  levelName: 'Nivel de embajador',
  packStatus: 'Estado del paquete',
  directExplain: 'Comisión sobre las tarifas de envío',
  indirectExplain:
    'Comisión sobre las tarifas de envío de invitados secundarios',
  experienceExplain: 'Puntos de experiencia requeridos',
  withdrawRecord: 'Registro de retiros',
  withdrawTime: 'Tiempo de retiro',
  serialNo: 'Número de serie',
  withdrawStatus: 'Estado del retiro',
  team: 'Equipo',
  levelUp: 'Subir de nivel',
  teamInfo: 'Información del equipo',
  teamNum: 'Número de usuarios del equipo',
  monthCommission: 'Comisión estimada para este mes',
  accCommission: 'Comisión acumulada liquidada',
  teamMember: 'Miembro del equipo',
  promotedMember: 'Miembro promocionado',
  invitationTime: 'Tiempo de invitación',
  inviter: 'Invitador',
  promotionTime: 'Tiempo de promoción',
  IBAN: 'IBAN',
  swiftCode: 'Código Swift',
  bankAddress: 'Dirección del banco',
  setting: 'Configuración',
  profile: 'Perfil',
  withdrawMethod: 'Método de retiro',
  myPrerogative: 'Mi prerrogativa',
  verifyEmail: 'Verifica tu correo electrónico',
  sendCodeTips:
    'Hemos enviado un código de verificación a <strong>{email}</strong>. Ingresa el código a continuación para verificar.',
  noGetEmail: '¿No recibiste el correo electrónico?',
  codeErrorTips: 'Error de código, por favor intenta de nuevo',
  resendTime: 'Reenviar el código ({time}s)',
  or: 'o',
  contactService: 'Contactar al servicio al cliente en línea',
  addMethod: 'Nuevo método de retiro',
  editMethod: 'Editar métodos de retiro',
  rePaypalAccount: 'Volver a ingresar la cuenta de PayPal',
  directInvite: 'Invitación directa',
  indirectInvite: 'Invitación indirecta',
  noSameAccount: 'La cuenta no coincide con la cuenta confirmada',
  noAddWithdrawMethod: 'No has añadido un método de retiro',
  statusEnum: {
    0: 'Pendiente de entrega',
    1: 'En proceso',
    2: 'Emitido con éxito',
    3: 'Fracaso de emisión',
    4: 'Revocación de retiros'
  },
  userDetail: 'Detalles para usuarios',
  balance: 'Saldo de la cuenta',
  withdrawMax: 'El monto del retiro debe ser superior a 500,00.'
};
