import request from '@/utils/request';
import { commonPrefix } from './config';

// 获取用户咨询回复
export const getTaskReply = params => {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    const res = await request.get(`${commonPrefix}/reply/v2/list`, {
      data: params
    });
    if (res.code === 200 || res.code === 0) {
      resolve(res.rows);
    } else {
      reject(res.msg);
    }
  });
};

// 获取用户咨询回复 /resource/reply 需携带 用户id 用户名
export const addTaskReply = params => {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    const res = await request.post(`${commonPrefix}/reply/userReply`, params);
    if (res.code === 200 || res.code === 0) {
      resolve(res.data);
    } else {
      reject(res.msg);
    }
  });
};

// 获取用户咨询
export const getUserConsult = params => {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    const res = await request.get(`${commonPrefix}/task/userTaskList`, {
      data: params
    });
    if (res.code === 200 || res.code === 0) {
      resolve(res);
    } else {
      reject(res.msg);
    }
  });
};

// 获取用户咨询统计
export function readTask(id) {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    const res = await request.get(
      `${commonPrefix}/task/readTask?id=${id}&readStatus=1`
    );
    if (res.code === 200 || res.code === 0) {
      resolve(res.data);
    } else {
      reject(res.msg);
    }
  });
}
// 获取可使用优惠券
export const getCouponList = () => {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    const res = await request.get(`${commonPrefix}/issueDetail/getList`);
    if (res.code === 200 || res.code === 0) {
      resolve(res.rows);
    } else {
      reject(res.msg);
    }
  });
};

// 获取全部优惠券
export const getAllCouponList = params => {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    const res = await request.get(`${commonPrefix}/issueDetail/getList`, {
      data: params
    });
    if (res.code === 200 || res.code === 0) {
      resolve(res);
    } else {
      reject(res.msg);
    }
  });
};
// 券兑换
export const exchangeCoupon = code => {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    const res = await request.post(
      `${commonPrefix}/issueDetail/exchange/${code}`, {}
    );
    if (res.code === 200 || res.code === 0) {
      resolve(res.data);
    } else {
      reject(res.msg);
    }
  });
};
// 获取用户ip地址
export const getIPAddress = () => {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    const res = await request.get(`${commonPrefix}/ip/info`);
    if (res.code === 200 || res.code === 0) {
      resolve(res.data);
    } else {
      reject(res.msg);
    }
  });
};

// 获取Faq数据
export const getDocIssue = code => {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    const res = await request.get(`${commonPrefix}/doc/issue?code=${code}`);
    if (res.code === 200 || res.code === 0) {
      resolve(res);
    } else {
      reject(res.msg);
    }
  });
};

// 获取Faq树形结构数据
export const getCategorytree = lang => {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    const res = await request.get(
      `${commonPrefix}/doc/category/tree?lang=${lang}&status=1`
    );
    if (res.code === 200 || res.code === 0) {
      resolve(res);
    } else {
      reject(res.msg);
    }
  });
};

// 获取类目下所有的faq列表
export const getCategoryId = categoryId => {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    const res = await request.get(`${commonPrefix}/doc/category/${categoryId}`);
    if (res.code === 200 || res.code === 0) {
      resolve(res);
    } else {
      reject(res.msg);
    }
  });
};
// 已读 咨询
export const readConsult = (params) => {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    const res = await request.post(`${commonPrefix}/task/read`, params);
    if (res.code === 200 || res.code === 0) {
      resolve(res);
    } else {
      reject(res.msg);
    }
  });
};
