/*
 * @Description:
 * @Author: 作者
 * @Date: 2024-11-26 16:26:23
 */
import axios from 'axios';
import CryptoJS from 'crypto-js';
import qs from 'qs';
import { useDistributionStore, useUserStore } from '@/stores';
import { acceptLangMap } from '@/config/constant';
import { USER_STORE_KEY } from '@/stores/config';
import { ElMessageBox } from 'element-plus';

// 创建一个 axios 实例
const instance = axios.create({
  timeout: 200000, // 请求超时时间毫秒
  withCredentials: false, // 异步请求携带cookie
  baseURL: '/'
});
let isShowReload = false;
//拦截器 请求拦截
instance.interceptors.request.use(
  async config => {
    //做点什么----根据后端约定执行相关 这里是判断开发/线上环境 存储添加token
    const localValue = localStorage.getItem(USER_STORE_KEY);
    const userStore = localValue ? JSON.parse(localValue) : {};
    const token = userStore.token;
    const lang = userStore.language;
    const store = useUserStore();

    if (config.method && config.method.toUpperCase() === 'GET') {
      config.data &&
        (config.url = `${config.url}?${qs.stringify(config.data)}`);
      config.data = null;
    }
    // 为了方便调试 建议在正式换下 对参数进行加密 测试环境不加密
    //if ( process.env.NODE_ENV === "production") {加密代码...}
    // 如果当前是POST提交时，对参数进行加密
    // if (config.method && config.method.toUpperCase() === 'POST') {
    //   if (process.env.NODE_ENV === 'production') {
    //     const payload = JSON.stringify(config.data);
    //     const key = CryptoJS.enc.Utf8.parse('abcdefgabcdefg12');
    //     const srcs = CryptoJS.enc.Utf8.parse(payload);
    //     const encrypted = CryptoJS.AES.encrypt(srcs, key, {
    //       mode: CryptoJS.mode.ECB,
    //       padding: CryptoJS.pad.Pkcs7
    //     });
    //     config.data = { encrypted: encrypted.toString() };
    //   }
    // }
    //判断是否存在token，根据后端约定在header的authorization都加上token
    if (token) {
      config.headers.Authorization = `${token}`;
      if (
        !isShowReload &&
        store.loginTimer &&
        store.loginTimer != userStore.loginTimer
      ) {
        if (store.profile.userId === userStore?.profile.userId) return;
        isShowReload = true;
        const data = require(`@/i18n/langs/${lang}/common.js`).default;
        try {
          await ElMessageBox.confirm(data.reloadPageTips, data.tips, {
            showCancelButton: false,
            showClose: false,
            closeOnClickModal: false,
            closeOnPressEscape: false,
            customClass: 'common-confirm',
            confirmButtonText: data.reload
          });
          location.reload();
          await new Promise();
        } catch (error) {
          await new Promise();
        }
      }
    }
    config.headers.lang = userStore.language;
    config.headers.Currency = userStore.currency;
    config.headers['Accept-Language'] = acceptLangMap[userStore.language];
    //根据后端约定执行相关 结束
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

//拦截器 响应拦截
instance.interceptors.response.use(
  response => {
    //做点什么----根据后端约定状态判断执行 这里是判断状态移除token
    if (response.data.status) {
      //
    } else {
      return response.data;
    }
    //根据后端约定状态判断执行 结束

    //可将 return response ↑放在做点什么中↑
    return response.data;
  },
  error => {
    if (error.response?.status === 401) {
      const userStore = useUserStore();
      const distributionStore = useDistributionStore();
      userStore.logout();
      distributionStore.logout();
      if (location.search.includes('redirectUrl')) return;
      const redirectUrl = encodeURIComponent(
        `${location.pathname}${location.search}`
      );
      location.href = `/login?redirectUrl=${redirectUrl}`;

      return;
    }

    return error;
  }
);

export default instance;
