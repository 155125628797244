export default {
  confirmOrder: 'Confirmer la commande',
  createSuccess: 'Commande créée avec succès',
  orderPrice: 'Prix total des commandes',
  otherAction: 'Autres opérations',
  orderStatus: 'Statut de la commande',
  afterSales: 'Services après-vente',
  allSelect: 'Tout sélectionner',
  orderNo: 'Numéro de commande',
  createTime: 'Date de création',
  productRemark: 'Remarques sur les produits',
  productRemarkHolder:
    "Message pour l'agent, rien n'est à spécifier si pas de demande d'achat spécifique.",
  orderDetail: 'Détails de la commande',
  goPay: 'Allez payer',
  cancelpay: 'Annuler le paiement',
  cancelPay: 'Annuler le paiement ?',
  cancelOrder: 'Annuler une commande?',
  cancelorder: 'Annuler une commande',
  cancelOrderProduct: 'Annuler un produit ?',
  cancelorderProduct: 'Annuler un produit',
  cancelPayResultHint:
    "Le paiement ne peut pas être restauré après annulation, veuillez sélectionner la raison de l'annulation du paiement",
  cancelOrderResultHint:
    "La commande ne peut pas être restaurée après annulation, veuillez sélectionner la raison de l'annulation de la commande",
  delOrder: 'Supprimer la commande',
  delOrderHint:
    "Êtes-vous sûr de vouloir supprimer l'ordre ? Si la marchandise a été remboursée, L'article ne sera pas renvoyé au vendeur pour un remboursement",
  cancelSuccess: 'Annulation réussie',
  delSuccess: 'Suppression réussie',
  orderBin: 'La station de recyclage',
  backOrder: 'Retour à mon ordre',
  restoreOrder: "Rétablir l'ordre",
  remark: 'Remarques',
  all: 'Tout',
  productName: "Nom de l'article",
  submitTime: 'Délai de soumission',
  orderConsult: "Demande d'ordre",
  appendService: 'Services supplémentaires',
  iKnow: 'Je comprends',
  cancelReturnBag: "Annuler le retour et l'échange",
  isCancelApply: 'Êtes-vous sûr de vouloir annuler la demande ?',
  goPay2: 'Aller effectuer le paiement complémentaire',
  backTax: 'Paiement complémentaire',
  cancelBackTax: 'Annuler le paiement complémentaire',
  productAttr: 'Attributs du produit',
  backTaxType: 'Type de paiement complémentaire',
  taxPrice: 'Prix du paiement complémentaire',
  productBackTax: 'Paiement complémentaire du produit',
  freightBackTax: 'Paiement complémentaire du fret',
  productTaxPrice: 'Prix du paiement complémentaire du produit',
  freightTaxPrice: 'Prix du paiement complémentaire du fret',
  totalTaxPrice: 'Prix total du paiement complémentaire',
  productStatus: 'Statut du produit',
  isNeedTax: 'Besoin de paiement complémentaire',
  yes: 'Oui',
  no: 'Non',
  iThinkAbout: 'Je vais y réfléchir',
  revokeCheckedProduct: 'Révoquer le produit sélectionné',
  taxTips1:
    "Veuillez [Effectuer le paiement complémentaire] ou [Annuler] dès que possible afin de ne pas affecter l'achat d'autres produits dans la commande.",
  cancelTaxTips1:
    'Si vous ne souhaitez pas effectuer le paiement complémentaire, nous ne pourrons pas acheter le produit qui nécessite un paiement complémentaire.',
  cancelTaxTips2:
    'Les produits marqués seront annulés après révocation, et le coût du produit sera remboursé sur votre compte.',
  cancelTaxTips3:
    "Les produits non marqués continueront d'être conservés et nous continuerons à les acheter pour vous.",
  cancelReason: {
    0: "Je ne veux pas l'acheter",
    1: 'Informations erronées',
    2: 'Stock dépassé chez le vendeur',
    3: 'Incapable de payer',
    4: 'Autres raisons'
  },
  hint: 'Avertissement',
  orderStatusEnum: {
    0: 'En attente de paiement',
    1: 'Non traité',
    2: 'En cours de vérification',
    3: 'Commande passée',
    4: 'Annulation de la commande',
    5: "Paiement complémentaire de frais d'expédition",
    6: 'Vérification de contrôle de risque',
    7: 'Annulation de commande',
    8: 'En cours de paiement',
    9: 'Terminé'
  },
  returnGood: {
    name: 'Retour/Échange',
    selectType: 'Sélectionnez le type',
    returnGood: 'Retour',
    returnDayTips: 'Généralement 3-7 jours',
    changeGood: 'Échange',
    changeDayTips: 'Généralement 5-10 jours',
    returnReasonEnum: {
      0: "Achat incorrect/je n'aime pas",
      1: 'Envoi incorrect de la part du vendeur ?',
      2: 'Problèmes de qualité du produit',
      3: "Envoi non effectué selon l'échéancier",
      5: 'Autres raisons'
    },
    returnReasonSelect: 'Raison de la sélection du retour',
    returnExplain: 'Instructions de retour',
    residueReturnNumTips:
      'Droits de retour sans frais de service restants ce mois-ci',
    returnTips:
      "Vérification finale du retour dû au vendeur ou à la plateforme, acbuy vous remboursera les frais d'expédition que vous supportez.",
    changeTips:
      "Pour les retours ou les échanges dus à des raisons du vendeur, vous pouvez toujours être responsable d'une partie des frais d'expédition de l'échange.",
    returnTip4: 'Avis amical',
    returnTip5:
      "Le service de retour et d'échange prévoit un nombre limité de retenues de frais d'opération sans frais. (Chaque utilisateur bénéficiera d'un premier service de retour ou d'échange sans frais d'opération mensuel, tandis que les frais d'expédition liés au retour ou à l'échange seront négociés entre le mandataire et le vendeur et recueillis par acbuy.) Frais de service de retour et d'échange d'acbuy : 5 Yuans par commande.",
    returnTip10:
      "Si le retour ou l'échange est imputable à la responsabilité de l'agent, du vendeur ou de la plateforme, le système vous remboursera les 5 Yuans de frais d'opération sur votre compte de solde acbuy."
  },
  holder: {
    selectCancelReason: 'Sélectionnez la raison du retrait de commande',
    inputOtherReason: 'Remplissez la raison',
    inputSearchHint: 'Nom / numéro de commande / numéro de produit.',
    inputCancelReason: 'Veuillez indiquer la raison'
  },
  selectProducts: 'Choisir un produit',
  confirmStepOrder: 'Confirmer la commande',
  PayForProducts: 'Payer pour le produit',
  sendingPackages: 'Envoyer un colis',
  submitDelivery: 'Soumettre pour livraison',
  readAgreeText: "J'ai lu et j'accepte",
  disclaimer: 'Disclaimer',
  orderNote:
    "Remarque : une fois le paiement effectué, vous devez soumettre votre colis pour la livraison internationale une fois qu'il est arrivé et stocké à l'entrepôt.",
  reminder: 'Rappel',
  disclaimer1: 'Description des articles interdits',
  disclaimer2: 'Conditions de service',
  disclaimer3: 'Politique de retour',
  customPhotosTips:
    'Une photo seulement disponible photographier un article une demande de détail',
  backCartEdit: 'Retour au panier modification',
  customPhotosReminderLabel: 'Rappel de bonne volonté',
  customPhotosReminder:
    "Une photo ne fournit qu'une seule exigence de détail pour prendre une photo du produit",
  packageAgreeTip1:
    "1. Les retards, dommages, pertes, taxes, confiscations et autres incertitudes liés aux services des prestataires logistiques échappent à notre contrôle et nous déclinons toute responsabilité en découlant. Cependant, nous ferons de notre mieux pour vous rappeler de réfléchir à deux fois avant d'acheter, pour vous aider à résoudre les problèmes de votre fournisseur logistique et pour vous indemniser conformément à la politique après - vente fournie par le fournisseur logistique.",
  packageAgreeTip2:
    'Nous vous recommandons de déclarer votre colis avec précision. Si votre colis est soumis à une inspection aléatoire lors du dédouanement dans le pays de destination, vous devrez peut - être payer des taxes. Si vous recevez un avis de taxe, Payez vos impôts en conséquence, sinon vous risquez de subir des conséquences telles que le paiement de frais de retour élevés, la destruction de colis, etc.',
  applyRefund: 'Demande de remboursement',
  refundReminder:
    'Conseils chaleureux: après la demande de remboursement, l’acheteur ira vérifier le statut des marchandises, et ne peut pas annuler la demande de remboursement avant la vérification.',
  inputApplyReason: 'Veuillez saisir la raison de votre demande',
  orderConfirm: 'Confirmer la commande',
  replyMsg: 'Répondre au message',
  replyMsgTips:
    'Les informations de remarque que vous envoyez, nous informerons l’acheteur pour vérifier pour vous.'
};
