export default {
  selectSize: '请选择完整规格',
  inventory: '库存',
  num: '数量',
  nimNumTips: '最低起批数量为：{num}',
  addCart: '加入购物车',
  nowBuy: '立即购买',
  remark: '留言',
  goShop: '进入店铺',
  productTotalPrice: '商品总价',
  productDetail: '商品详情',
  sizeParams: '规格参数',
  shopNotes: '购物代购须知',
  saleService: '售后服务',
  productInfo: '商品信息',
  buyTips: '支付后，我们会在09:00-18:00（UTC+8）为您进行代购服务',
  buyer: '商家',
  selfWarehouse: 'acbuy仓库',
  warehouse: 'acbuy仓库',
  yourAddress: '你的地址',
  calcCountryFreight: '估算国际运费',
  holder: {
    remarkHolder:
      '您给采购员的留言，没有特殊要求的可不写；您的留言，采购员会根据商家及其他情况予以决定是否执行。'
  },
  supportPayment: '支持支付',
  disclaimer:
    'acbuy上显示的所有可用于购物代理服务的产品均来自第三方购物平台，并非acbuy直接销售。因此，acbuy对侵犯知识产权和侵犯商品版权所造成的问题不承担任何责任和法律责任。',
  readAgree: '我已阅读并同意上面',
  selectAgree: '还没同意免责声明',
  copyLink: '复制链接',
  copySuccess: '复制成功',
  disclaimer1: '免责声明',
  editPrice: '改价',
  editPriceTips:
    '受购物券领取规则影响，领券或满减优惠可能无法享受，建议您修改的价格与卖家实际销售价格一致！',
  editPriceEnum: {
    0: '运输折扣',
    1: '网页报价错误',
    2: '与卖方协商',
    3: '其他原因'
  }
};
