export default {
  addShopSuccess: 'Produto adicionado com sucesso',
  addShopFail: 'Falha ao adicionar produto',
  getCartDataFail: 'Falha ao obter produtos do carrinho',
  hint: 'Dica',
  delConfirmProduct: 'Tem certeza de que deseja excluir o produto?',
  confirm: 'Confirmar',
  cancel: 'Cancelar',
  productInfo: 'Detalhes do produto',
  unitPrice: 'Preço unitário',
  num: 'Quantidade',
  subtotal: 'Subtotal',
  action: 'Ação',
  productName: 'Nome do produto',
  splitProduct: 'Dividir produto',
  split: 'Dividir',
  allSelect: 'Selecionar todos',
  delProduct: 'Excluir produto',
  selected: 'Selecionado',
  piece: 'Item(ns)',
  total: 'Total',
  productTotal: 'Não inclui taxa de entrega internacional',
  orderPay: 'Finalizar compra',
  orderTotal: 'Total do pedido',
  goCartPay: 'Ver carrinho',
  moreView: 'Mais itens no carrinho',
  noSelectText: 'Nenhum produto selecionado ainda',
  paySuccess: 'Pagamento bem-sucedido!',
  returnPayment: 'Voltar para continuar o pagamento',
  service: 'Serviço',
  goodsMinNumErrTips:
    'Este item requer uma quantidade mínima de compra {num}. Você pode escolher diferentes especificações de acordo com suas necessidades.',
  balckShop: 'A loja está na lista negra.',
  forbidBuy: 'Loja de risco, proibida de comprar',
  riskTip: 'Lojas de risco, por favor compre com cuidado'
};
