export default {
  noSamePass: 'la contraseña es diferente de la contraseña de confirmación',
  loginSuccess: 'inicio de sesión exitoso',
  registerSuccess: 'Registro exitoso',
  isHaveAccount: '¿Ya tienes una cuenta?',
  login: 'Iniciar sesión',
  logout: 'Cerrar sesión',
  forgetPass: '¿Olvidaste tu contraseña?',
  register: 'Registrarse',
  registered: 'Ya registrado,',
  emailTips: 'Correo electrónico',
  password: 'Contraseña',
  confirmPass: 'Confirmar contraseña',
  countryTips: 'Selecciona tu país',
  inviteCodeTips: 'Ingresa el código de invitación (opcional)',
  verifyEmpty: 'la información no puede estar vacía',
  verifyEmail: 'información incorrecta',
  verifyPass:
    'la contraseña está limitada de 6 a 20 caracteres y debe contener letras y números',
  confirmText: 'He leído y acepto',
  agreementName: '《Acuerdo de registro de usuario de PandaBuy',
  verifyCode: 'Código de verificación de correo electrónico',
  loginPwd: 'Contraseña de inicio de sesión',
  editPwd: 'Modificar contraseña',
  changePwd: 'Cambiar contraseña de inicio de sesión',
  setPwd: 'Establecer contraseña',
  and: 'y',
  privacyStatement: '《declaración de privacidad》',
  confirm: 'Confirmar',
  cancel: 'Cancelar',
  editSuccess: 'Modificación exitosa',
  email: 'Correo electrónico',
  emailCode: 'Verificación de correo electrónico',
  newPwd: 'Nueva contraseña',
  button: {
    search: 'Buscar',
    query: 'VERIFICAR AHORA',
    reset: 'Restablecer',
    submit: 'Enviar',
    submitOrder: 'Enviar pedido',
    save: 'Guardar'
  },
  holder: {
    select: 'por favor seleccione',
    startTime: 'Hora de inicio',
    endTime: 'Hora de finalización',
    max: 'máximo',
    min: 'mínimo',
    inputContent: 'Por favor ingrese contenido',
    inputEmailAccount:
      'Por favor ingrese una cuenta de correo electrónico correcta'
  },
  back: '¡Bienvenido de vuelta!',
  continueGoogle: 'Continuar con Google',
  notAccount: '¿No tienes una cuenta?',
  signNow: 'Regístrate ahora',
  termService: 'Términos de servicio',
  privacyPolicy: 'Política de privacidad',
  helpCenter: 'Centro de ayuda',
  readPrivacy: 'Ya he leído y acepto',
  verifyAccept: 'no has leído el acuerdo',
  accountSecurity: 'Seguridad de la cuenta',
  loginNow: 'Iniciar sesión ahora',
  resetPsd: 'Restablecer contraseña',
  verifiedEmail: 'Verificar correo electrónico',
  unverified: 'no verificado',
  emailVerification: 'Verificación de correo electrónico',
  verifyMsg:
    'Tu correo electrónico no ha sido verificado, por lo que temporalmente no podemos completar la operación de compra. Por favor verifica tu correo electrónico en el centro personal.',
  sendVerificationEmail: 'Enviar correo de verificación',
  linkHasExpired: '¡El enlace ha expirado!',
  hasSendEmail: 'Se ha enviado el correo de verificación',
  shippingFeeEstimation: 'Estimación de gastos de envío',
  homeText: 'Página principal',
  loginBgMsg: 'Únete a acbuy Discord para obtener más beneficios',
  registerTitleMsg: 'COMIENZA TU VIAJE',
  registerBgMsg: 'Los nuevos registros reciben cupones de envío de 340$',
  announcement: 'Anuncio',
  formAnnouncementMsg:
    'Un nuevo modelo para el programa de afiliados, modelo de comisión de paquete dual, úsalo para ganar dinero y ahorrar dinero',
  incorrectAmount: 'El monto del retiro debe ser mayor que 0',
  readPrivacy1: 'He leído',
  andAgree: 'y acepto',
  aboveContent: 'el contenido anterior',
  purchasingNotice: 'Aviso de compra',
  afterSaleService: 'servicio postventa',
  uniOrderAgreeText:
    'Los enlaces que envía en esta página pertenecen a enlaces de productos de terceros (como xianyu, xiaohongshu, bilibili, etc.), y acbuy solo puede ayudarlo a encontrar comerciantes de este enlace para consultas de productos, compras y organizar servicios de distribución logística de terceros. acbuy no puede distinguir entre la calidad y la autenticidad del producto, y necesita asumir los riesgos relevantes antes de hacer el pedido.',
  excludeFees:
    'Consejo importante: el flete internacional se calculará por separado.'
};
