export default {
  myBalance: '我的余额',
  topUp: '充值',
  pay: '充值',
  repayment: '还款',
  balance: '余额',
  usableBalance: '可用余额',
  inputBalance: '请输入金额',
  payBalance: '充值金额',
  pecenter: '个人中心',
  lang: '语言',
  cart: '购物车',
  recipientName: '收货人',
  phone: '电话',
  region: '区域',
  address: '详细地址',
  country: '国家',
  province: '省',
  city: '城市',
  postcode: '邮编',
  action: '操作',
  mobileSettings: '移动设置',
  name: '姓名',
  debt: '欠款',
  add: '添加信用卡账单地址',
  addSuccess: '新增成功',
  editSuccess: '修改成功',
  editBtn: '编辑',
  edit: '修改信用卡账单地址',
  del: '删除',
  setDefault: '设为默认',
  defaultAddress: '默认地址',
  setDefaultAddress: '设置为默认收货地址',
  setSuccess: '设置成功',
  myAddress: '我的地址',
  addAddress: '添加新地址',
  tradeTime: '交易时间',
  type: '类型',
  incomeOrExpend: '收入/支出',
  accountBalance: '账户余额',
  tradeNo: '交易编号',
  remark: '备注',
  tag: '地址标签',
  addBillAddress: '添加账单地址',
  deleteBillAddress: '删除账单地址',
  editBillAddress: '修改账单地址',
  mergePay: '合并支付',
  username: '用户名',
  gender: '性别',
  birthday: '生日',
  avatar: '头像',
  email: '邮箱',
  site: '位置',
  myOrder: '我的订单',
  goRecharge: '去充值',
  sideBar: {
    myAccount: '我的账户',
    addressManage: '地址管理',
    myAsset: '我的资产',
    billAddress: '账单地址'
  },
  holder: {
    receiver: '收货人',
    firstName: '姓',
    name: '名',
    region: '请选择省/市/区/街道',
    address: '请输入详细地址信息，如道路、门牌号、小区、楼栋号、单元等信息',
    phone: '电话号码、手机号码必须填一项',
    input: '请输入',
    select: '请选择',
    tagHolder: '最多120个字（选填）',
    postCodeNoMatch: '邮编与区域不匹配'
  },
  forget: {
    accountSafe: '账号安全',
    setPwd: '修改密码',
    inputBindEmail: '请输入您绑定的邮箱',
    inputVerifyCode: '请输入验证码',
    inputPwd: '请输入新密码'
  },
  accountInfo: {
    name: '账号信息',
    selfInfo: '个人信息',
    cardBillAddress: '信用卡账单地址',
    shipAddress: '收货地址',
    editUser: '修改用户信息',
    inputCountry: '请输入您的国家',
    editEmail: '修改邮箱',
    editEmailHint: '（建议仅在邮箱设置错误或出现问题时修改）',
    editEmailHint1:
      '为了您的账户安全，需要先通过"邮箱验证码+登录密码"进行安全校验',
    editEmailHint2: '为了您的账户安全，需要先通过"邮箱验证码"进行安全校验',
    currentEmail: '当前邮箱',
    clickVerify: '发送',
    reGet: '重新获取',
    nextStep: '下一步',
    notReGetCode: '后重新获取',
    logoutAccount: '注销账号',
    emailNotEmpty: '邮箱不能为空',
    logoutReason: '请选择注销原因',
    latelyLogin: '最近登录',
    moreRecord: '更多记录',
    visitRecord: '访问记录',
    visitTime: '访问时间',
    ipAndDevice: 'IP地址&设备类型',
    editLoginPwdHint:
      '安全性高的密码可以使账号更安全。建议您定期更换密码，且设置一个包含数字和字母，并长度超过6位以上的密码。',
    editPayPwdHint:
      '使用acbuy余额支付时需要输入的密码，设置支付密码以保障支付安全。',
    editVerifyEmailHint: '为了保护您的帐户安全，请验证您的电子邮件。',
    payPwd: '支付密码',
    editPayPwd: '修改支付密码',
    setPayPwd: '设置支付密码',
    deleteAccount: '删除账户'
  },
  logoutReasonEnum: {
    0: '安全/隐私问题',
    1: '糟糕的客户服务',
    2: '费用太高了',
    3: '复杂的购物过程',
    4: '其他原因'
  },
  tradeTypeEnum: {
    0: '收入',
    1: '支出'
  },
  asset: {
    currentBalance: '当前余额',
    canWithdrawBalance: '可提现金额',
    withdraw: '提现',
    serialNo: '序列号',
    amount: '金额',
    lossAmount: '消费金额',
    withdrawAmount: '提现金额',
    inputWithdrawAmount: '请输入提现金额',
    withdrawSuccess: '提现申请提交成功',
    transactionDate: '交易日期',
    withdrawMethod: '提现方式',
    availableAmount: '可用金额',
    transactionNo: '交易号',
    withdrawMsg:
      '注意：余额提现将通过原渠道退回。余额将按照历史支出记录，退回到提现交易的支付账户内。',
    transactionRecordsText: '可用金额交易记录',
    withdrawRecordText: '提现记录'
  },
  package: {
    packageInfo: '包裹信息',
    weightHaveUnit: '重量（克）',
    sizeHaveUnit: '尺寸（厘米）',
    freight: '运费',
    fee: '配送费',
    transportMode: '配送方式',
    allPackage: '所有包裹',
    myPackage: '我的包裹'
  },
  packageStatusEnum: {
    0: '待补款',
    1: '待付款',
    2: '运输中',
    3: '已完成',
    4: '已取消'
  },
  storage: {
    name: '仓库',
    warehouseTime: '入库时间',
    submitTransport: '提交配送',
    lineCanUse: '可用件数',
    commonImg: '质检照片',
    customPhoto: '定制照片',
    extendCare: '延长存储',
    extendHint: '您的商品将在仓库中延长存储（30*N）天，到期时间为',
    packMode: '包装方式',
    retainPack: '保留包装',
    normalPack: '默认包装',
    normalPackHint: '商品将使用原包装进行打包。',
    foldPack: '折叠包装',
    foldPackHint:
      '对于非胶水固定的纸箱，仓库将拆解它们，折叠压平后放入您的包裹中，这在某种程度上可以减少包裹体积。但也存在增加包裹体积的情况（当商品较少时，纸箱折叠的长度和宽度可能会增加）。',
    discardPack: '丢弃包装',
    discardPackHint:
      '包装丢弃后，卖家可能不接受任何售后，且包装无法找回/重新添加。',
    photoTemplate: '照片模板'
  },
  operate: '操作',
  editAddress: '编辑地址',
  underPaid: '待付款',
  goTopay: '去支付',
  viewRecord: '查看记录',
  faq: '常见问题',
  question1: '问：如果我提交提现申请，我的账户会被冻结吗？',
  response1:
    '答：申请提现后，账户会冻结申请部分的金额，以保证退款金额与您申请金额一致',
  question2: '问：提现记录上的"已完成"是否意味着资金提现成功？',
  response2:
    '答："已完成"表示acbuy已安排退款，但到达您的原始账户所需的时间取决于第三方平台的处理时间。',
  question3: '问：提现是否基于实时汇率？',
  response3: '答：不是，提现汇率是基于资金充值时的汇率。',
  question4: '问：当账户余额与提现金额不同时，如何提现？',
  response4:
    '答：当账户余额大于可提现金额时，您可以分次申请，直到账户余额为0。当账户余额小于可提现金额时，您可以申请一次性全额提现。',
  stillRecharge: '余额不足以支付订单，仍需充值',
  amountDue: '应付金额',
  handingFee: '手续费',
  coupon: '优惠券',
  couponPlaceholder: '使用优惠券代码兑换优惠券',
  off: '折扣',
  minFee: '最低费用',
  freightText: '运费',
  limitTo: '限制',
  shippingUsage: '运输用途',
  availableWarehouse: '可用仓库',
  shippingFeeText: '运费（不包括清关费）',
  internationalParcel: '国际包裹',
  to: '至',
  availableCoupons: '可用优惠券',
  usedCoupons: '已使用优惠券',
  expiredCoupons: '已过期优惠券',
  exchangeSuccess: '兑换成功！',
  myAffiliate: {
    name: '我的分销'
  },
  affiliateShare: '分享',
  copyText: '复制',
  affiliateShareMsg: '通过共享链接进行的运费支付将为您带来佣金收入。',
  businessType: '业务类型',
  payWay: '交易类型',
  bzOrderNo: 'AC单号',
  amountDueTooltip: '由于币种汇率间转换计算，可能会产生约0.01或0.02的差额',
  balanceStatement: '余额流水',
  billFlow: '账单流水'
};
